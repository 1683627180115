import * as parameterInfo from "@duet/shared/app_data/DUET-Designer_ParameterInfo.json";
import { makeBlockModel as _makeBlockModel } from "@duet/shared/models/BlockModel";
import { range } from "lodash";
import * as luxon from "luxon";
import { Path } from "react-hook-form";
import { z } from "zod";
import { Block, BlockFragment, BlockInput, TerrainType } from "~/gql/generated";
import { InputFieldMetadata, Unit } from "../pvComponents/types";
import { validateSoilingLoss } from "../utils/validation";

export type BlockParams = Omit<
  BlockInput,
  "id" | "project_id" | "follow_slope"
>;

export type BlockSimParams = Pick<
  Block,
  | "pv_system_start_date"
  | "cleaning_interval_days"
  | "soiling_rate"
  | "date_of_most_recent_cleaning"
  | "enviro_dataset_id"
  | "power_factor"
  | "pv_soiling_algorithm"
  | "soiling_rates_percentage"
>;

export const blockSimParams: Array<keyof BlockSimParams> = [
  "pv_system_start_date",
  "date_of_most_recent_cleaning",
  "enviro_dataset_id",
  "cleaning_interval_days",
  "soiling_rate",
  "power_factor",
  "pv_soiling_algorithm",
  "soiling_rates_percentage",
];

const soilingMonthlySchema = z.object({
  pv_soiling_algorithm: z.literal("monthly"),
  soiling_rates_percentage: z
    .array(z.number().gte(0.0).lte(100.0).default(0.0))
    .length(12),
});

const soilingSawtoothSchema = z.object({
  pv_soiling_algorithm: z.literal("sawtooth"),
  soiling_rate: z.number().gte(0.0).lte(100.0).default(0.0),
  cleaning_interval_days: z.number().int().positive(),
  date_of_most_recent_cleaning: z.string(),
});

const baseBlockSimParamSchema = z.object({
  power_factor: z.number().gte(0).lte(1).default(1),
  pv_system_start_date: z.string(),
  pv_soiling_algorithm: z.string(),
});

export const combinedBlockSimParamSchema = soilingMonthlySchema
  .merge(soilingSawtoothSchema)
  .merge(baseBlockSimParamSchema);

const invalidSoilingLossMessage = "Soiling loss will result in 100% loss";

export const blockSimParamSchema = z
  .discriminatedUnion("pv_soiling_algorithm", [
    baseBlockSimParamSchema.merge(soilingMonthlySchema),
    baseBlockSimParamSchema.merge(soilingSawtoothSchema),
  ])
  .refine((params) => validateSoilingLoss(params), {
    message: invalidSoilingLossMessage,
    path: ["cleaning_interval_days"],
  });

export const baseBlockSchema = z.object({
  pv_panel_id: z.string(),
  pv_table_id: z.string(),
  pv_inverter_id: z.string(),
  pv_transformer_id: z.string(),
  pv_dc_wire_id: z.string(),
  pv_ac_wire_id: z.string(),
  num_inverters: z.number().int().positive(),
  slope_azimuth: z.number().gte(-180.0).lte(180.0).nullish().default(0.0),
  slope_tilt: z.number().gte(0.0).lte(45.0).nullish().default(0.0),
  slope_aware_tracking: z.boolean().nullish().default(true),
  terrain_type: z.string(),
});

export const blockSchema = z
  .discriminatedUnion("pv_soiling_algorithm", [
    baseBlockSchema.merge(baseBlockSimParamSchema).merge(soilingMonthlySchema),
    baseBlockSchema.merge(baseBlockSimParamSchema).merge(soilingSawtoothSchema),
  ])
  .refine((params) => validateSoilingLoss(params), {
    message: invalidSoilingLossMessage,
    path: ["cleaning_interval_days"],
  });

export const blockInputMetadata: Record<
  Path<
    Omit<
      BlockParams,
      | "name"
      | "pv_panel_id"
      | "pv_table_id"
      | "pv_inverter_id"
      | "pv_transformer_id"
      | "pv_dc_wire_id"
      | "pv_ac_wire_id"
    >
  >,
  InputFieldMetadata<BlockParams>
> = {
  slope_aware_tracking: {
    labelText: "Slope Aware Tracking",
    helpText: "If enabled, tracking follows the ground slope.",
    options: [
      { label: "On", value: true },
      { label: "Off", value: false },
    ],
  },
  slope_azimuth: {
    labelText: "Ground Slope Azimuth",
    helpText: "Ground azimuth angle (South=0, East=90, West=-90, North=180)",
    unit: "°",
  },
  slope_tilt: {
    labelText: "Ground Slope Tilt",
    helpText:
      "Ground elevation angle (reference position south, tilt is positive for higher ground to north)",
    unit: "°",
  },
  terrain_type: {
    labelText: "Terrain Type",
    options: [
      { label: "Flat", value: TerrainType.Flat },
      { label: "Slope", value: TerrainType.Slope },
      { label: "Mesh", value: "dem" },
    ],
  },
  num_inverters: {
    labelText: "# of Inverters",
  },
  pv_soiling_algorithm: {
    labelText: parameterInfo.pv_soiling_algorithm.display_name,
    helpText: parameterInfo.pv_soiling_algorithm.description,
    options: [
      { label: "Monthly", value: "monthly" },
      { label: "Sawtooth", value: "sawtooth" },
    ],
  },
  soiling_rates_percentage: {
    labelText: parameterInfo.soiling_rates_percentage.display_name,
    helpText: parameterInfo.soiling_rates_percentage.description,
    unit: parameterInfo.soiling_rates_percentage.units as Unit,
  },
  ...range(12).reduce<
    Record<
      `soiling_rates_percentage.${number}`,
      InputFieldMetadata<BlockParams>
    >
  >((acc, curr) => {
    acc[`soiling_rates_percentage.${curr}`] = {
      labelText: luxon.Info.months("short")[curr],
      fullLabelText: `${parameterInfo.soiling_rates_percentage.display_name} (${
        luxon.Info.months("short")[curr]
      })`,
      unit: parameterInfo.soiling_rates_percentage.units as Unit,
    };
    return acc;
  }, {}),
  soiling_rate: {
    labelText: parameterInfo.soiling_rate.display_name,
    helpText: parameterInfo.soiling_rate.description,
    unit: parameterInfo.soiling_rate.units as Unit,
  },
  cleaning_interval_days: {
    labelText: parameterInfo.cleaning_period.display_name,
    helpText: parameterInfo.cleaning_period.description,
    unit: parameterInfo.cleaning_period.units as Unit,
  },
  date_of_most_recent_cleaning: {
    labelText: parameterInfo.cleaning_date.display_name,
    helpText: parameterInfo.cleaning_date.description,
  },
  pv_system_start_date: {
    labelText: parameterInfo.pv_system_start_date.display_name,
    helpText: parameterInfo.pv_system_start_date.description,
  },
  enviro_dataset_id: {
    labelText: "Meteo Dataset",
  },
  power_factor: {
    labelText: parameterInfo.power_factor.display_name,
    helpText: parameterInfo.power_factor.description,
  },
};

export interface BlockModelClientState {
  isVisible: boolean;
}

const EMPTY_CLIENT_STATE: BlockModelClientState = {
  isVisible: true,
} as const;

export const CLIENT_STATE_KEYS = Object.keys(EMPTY_CLIENT_STATE) as Array<
  keyof typeof EMPTY_CLIENT_STATE
>;

/**
 * TODO: perhaps we just embed the actual BlockModel in another struct like:
 * interface BlockModel {
 *   // server block
 *  block: BlockFragment;
 *  // client state
 *  ...
 * }
 */
export interface BlockModel extends BlockFragment, BlockModelClientState {}

export function makeBlockModel(block?: Partial<BlockModel>): BlockModel {
  const blockModel: BlockModel = {
    ...EMPTY_CLIENT_STATE,
    ..._makeBlockModel(block),
  };

  return blockModel;
}
