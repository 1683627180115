import * as parameterInfo from "@duet/shared/app_data/DUET-Designer_ParameterInfo.json";
import {
  ComponentType,
  MechanicalShapeEnum,
  PanelOrientationEnum,
} from "@duet/shared/pvComponents/projectParameters";
import { ComponentInputMetadata, Unit } from "@duet/shared/pvComponents/types";
import { isEmptyNumberInput } from "@duet/shared/pvComponents/utils";
import { isNumber } from "lodash";

export const tableInputMetadata: ComponentInputMetadata<ComponentType.Table> = {
  num_tiers: {
    labelText: parameterInfo.number_of_tiers.display_name,
    helpText: parameterInfo.number_of_tiers.description,
  },
  num_cols: {
    labelText: parameterInfo.panel_columns_in_each_table.display_name,
    helpText: parameterInfo.panel_columns_in_each_table.description,
  },
  row_spacing: {
    labelText: parameterInfo.row_spacing.display_name,
    helpText: parameterInfo.row_spacing.description,
    unit: parameterInfo.row_spacing.units as Unit,
  },
  azimuth: {
    labelText: parameterInfo.array_azimuth_angle.display_name,
    helpText: parameterInfo.array_azimuth_angle.description,
    options: [
      { value: 180, label: "180°" },
      { value: 90, label: "90°" },
      { value: 0, label: "0°" },
      { value: -90, label: "-90°" },
    ],
  },
  orientation_str: {
    labelText: parameterInfo.panel_orientation.display_name,
    helpText: parameterInfo.panel_orientation.description,
    options: [
      { value: PanelOrientationEnum.PORTRAIT, label: "Portrait" },
      { value: PanelOrientationEnum.LANDSCAPE, label: "Landscape" },
    ],
  },
  column_gap: {
    labelText: parameterInfo.column_gap.display_name,
    helpText: parameterInfo.column_gap.description,
    unit: parameterInfo.column_gap.units as Unit,
  },
  table_gap: {
    labelText: parameterInfo.table_gap.display_name,
    helpText: parameterInfo.table_gap.description,
    unit: parameterInfo.table_gap.units as Unit,
  },
  tier_gap: {
    labelText: parameterInfo.tier_gap.display_name,
    helpText: parameterInfo.tier_gap.description,
    unit: parameterInfo.tier_gap.units as Unit,
    disabledIf: ({ num_tiers }) => isNumber(num_tiers) && num_tiers < 2,
  },
  ground_clearance: {
    labelText: parameterInfo.ground_clearance.display_name,
    helpText: parameterInfo.ground_clearance.description,
    unit: parameterInfo.ground_clearance.units as Unit,
    disabledIf: ({ tracking_type }) => tracking_type !== 0,
  },
  torque_tube_height: {
    labelText: parameterInfo.torque_tube_height.display_name,
    helpText: parameterInfo.torque_tube_height.description,
    unit: parameterInfo.torque_tube_height.units as Unit,
    disabledIf: ({ tracking_type }) => tracking_type !== 1,
  },
  tilt: {
    labelText: parameterInfo.array_tilt_angle.display_name,
    helpText: parameterInfo.array_tilt_angle.description,
    unit: parameterInfo.array_tilt_angle.units as Unit,
    disabledIf: ({ tracking_type }) =>
      isNumber(tracking_type) && tracking_type !== 0,
  },
  stow_angle: {
    labelText: parameterInfo.tracker_stow_angle.display_name,
    helpText: parameterInfo.tracker_stow_angle.description,
    unit: parameterInfo.tracker_stow_angle.units as Unit,
    disabledIf: ({ tracking_type }) =>
      isNumber(tracking_type) && tracking_type !== 1,
  },
  backtracking_enabled: {
    labelText: parameterInfo.backtracking.display_name,
    helpText: parameterInfo.backtracking.description,
    disabledIf: ({ tracking_type }) =>
      isNumber(tracking_type) && tracking_type !== 1,
  },
  track_angle_bounds: {
    labelText: parameterInfo.tracker_angle_bounds.display_name,
    helpText: parameterInfo.tracker_angle_bounds.description,
    disabledIf: ({ tracking_type }) =>
      isNumber(tracking_type) && tracking_type !== 1,
  },
  "track_angle_bounds.0": {
    labelText: "Min",
    unit: parameterInfo.tracker_angle_bounds.units as Unit,
    disabledIf: ({ tracking_type }) =>
      isNumber(tracking_type) && tracking_type !== 1,
  },
  "track_angle_bounds.1": {
    labelText: "Max",
    unit: parameterInfo.tracker_angle_bounds.units as Unit,
    disabledIf: ({ tracking_type }) =>
      isNumber(tracking_type) && tracking_type !== 1,
    errorIf: ({ track_angle_bounds }) => {
      if (
        track_angle_bounds &&
        !isEmptyNumberInput(track_angle_bounds?.[0]) &&
        !isEmptyNumberInput(track_angle_bounds?.[1]) &&
        track_angle_bounds[1] < track_angle_bounds[0]
      ) {
        return {
          message: "must be no less than Min",
        };
      }
    },
  },
  tracking_type: {
    labelText: parameterInfo.tracking_type.display_name,
    helpText: parameterInfo.tracking_type.description,
    options: [
      { value: 0, label: "Fixed" },
      { value: 1, label: "Single-axis" },
    ],
  },
  torque_tube_enabled: {
    labelText: parameterInfo.torque_tube.display_name,
    helpText: parameterInfo.torque_tube.description,
  },
  torque_tube_shape: {
    labelText: parameterInfo.torque_tube_shape.display_name,
    helpText: parameterInfo.torque_tube_shape.description,
    options: [
      { value: MechanicalShapeEnum.Rect, label: "Rectangular" },
      { value: MechanicalShapeEnum.Round, label: "Round" },
    ],
    disabledIf: ({ torque_tube_enabled }) => !torque_tube_enabled,
  },
  torque_tube_radius: {
    labelText: parameterInfo.torque_tube_radius.display_name,
    helpText: parameterInfo.torque_tube_radius.description,
    unit: parameterInfo.torque_tube_radius.units as Unit,
    disabledIf: ({ torque_tube_enabled, torque_tube_shape }) =>
      !torque_tube_enabled || torque_tube_shape === MechanicalShapeEnum.Rect,
  },
  rotation_radius: {
    labelText: parameterInfo.radius_of_rotation.display_name,
    helpText: parameterInfo.radius_of_rotation.description,
    unit: parameterInfo.radius_of_rotation.units as Unit,
    disabledIf: ({ torque_tube_enabled }) => !torque_tube_enabled,
    errorIf: ({ torque_tube_radius, rotation_radius }) => {
      if (
        !isEmptyNumberInput(torque_tube_radius) &&
        !isEmptyNumberInput(rotation_radius) &&
        rotation_radius < torque_tube_radius
      ) {
        return {
          message: "must be greater than or equal to Torque Tube Radius",
        };
      }
    },
  },
  torque_tube_rect_dimensions: {
    labelText: parameterInfo.torque_tube_rectangular_size.display_name,
    helpText: parameterInfo.torque_tube_rectangular_size.description,
    disabledIf: ({ torque_tube_enabled, torque_tube_shape }) =>
      !torque_tube_enabled || torque_tube_shape === MechanicalShapeEnum.Round,
  },
  "torque_tube_rect_dimensions.0": {
    labelText: "Width",
    unit: parameterInfo.torque_tube_rectangular_size.units as Unit,
    disabledIf: ({ torque_tube_enabled, torque_tube_shape }) =>
      !torque_tube_enabled || torque_tube_shape === MechanicalShapeEnum.Round,
  },
  "torque_tube_rect_dimensions.1": {
    labelText: "Height",
    unit: parameterInfo.torque_tube_rectangular_size.units as Unit,
    disabledIf: ({ torque_tube_enabled, torque_tube_shape }) =>
      !torque_tube_enabled || torque_tube_shape === MechanicalShapeEnum.Round,
  },
  vertical_post_enabled: {
    labelText: parameterInfo.vertical_post.display_name,
    helpText: parameterInfo.vertical_post.description,
  },
  vertical_post_shape: {
    labelText: parameterInfo.vertical_post_shape.display_name,
    helpText: parameterInfo.vertical_post_shape.description,
    options: [
      { value: MechanicalShapeEnum.Rect, label: "Rectangular" },
      { value: MechanicalShapeEnum.Round, label: "Round" },
    ],
    disabledIf: ({ vertical_post_enabled }) => !vertical_post_enabled,
    errorIf: ({ tracking_type, vertical_post_shape }) => {
      if (
        tracking_type === 0 &&
        vertical_post_shape === MechanicalShapeEnum.Rect
      ) {
        return {
          message:
            "Rectangular vertical posts not yet supported for fixed tilt",
        };
      }
    },
  },
  vertical_post_radius: {
    labelText: parameterInfo.vertical_post_radius.display_name,
    helpText: parameterInfo.vertical_post_radius.description,
    unit: parameterInfo.vertical_post_radius.units as Unit,
    disabledIf: ({ vertical_post_enabled, vertical_post_shape }) =>
      !vertical_post_enabled ||
      vertical_post_shape === MechanicalShapeEnum.Rect,
  },
  vertical_post_distance: {
    labelText: parameterInfo.vertical_post_locations_along_row.display_name,
    helpText: parameterInfo.vertical_post_locations_along_row.description,
    unit: parameterInfo.vertical_post_locations_along_row.units as Unit,
    disabledIf: ({ vertical_post_enabled }) => !vertical_post_enabled,
  },
  vertical_post_dimensions: {
    labelText: parameterInfo.vertical_post_rectangular_size.display_name,
    helpText: parameterInfo.vertical_post_rectangular_size.description,
    disabledIf: ({ vertical_post_enabled, vertical_post_shape }) =>
      !vertical_post_enabled ||
      vertical_post_shape === MechanicalShapeEnum.Round,
  },
  "vertical_post_dimensions.0": {
    labelText: "Width",
    unit: parameterInfo.vertical_post_rectangular_size.units as Unit,
    disabledIf: ({ vertical_post_enabled, vertical_post_shape }) =>
      !vertical_post_enabled ||
      vertical_post_shape === MechanicalShapeEnum.Round,
  },
  "vertical_post_dimensions.1": {
    labelText: "Height",
    unit: parameterInfo.vertical_post_rectangular_size.units as Unit,
    disabledIf: ({ vertical_post_enabled, vertical_post_shape }) =>
      !vertical_post_enabled ||
      vertical_post_shape === MechanicalShapeEnum.Round,
  },
  num_post_cols: {
    labelText: parameterInfo.number_of_posts_along_row.display_name,
    helpText: parameterInfo.number_of_posts_along_row.description,
    disabledIf: ({ vertical_post_enabled }) => !vertical_post_enabled,
    errorIf: ({ vertical_post_shape, num_post_cols }) => {
      if (
        vertical_post_shape === MechanicalShapeEnum.Round &&
        !isEmptyNumberInput(num_post_cols) &&
        num_post_cols < 2
      ) {
        return {
          message: "Round vertical posts must have at least 2 posts along row",
        };
      }
    },
  },
  num_post_tiers: {
    labelText: parameterInfo.number_of_posts_along_tiers.display_name,
    helpText: parameterInfo.number_of_posts_along_tiers.description,
    disabledIf: ({ vertical_post_enabled }) => !vertical_post_enabled,
    errorIf: ({ tracking_type, num_post_tiers }) => {
      if (
        tracking_type === 0 &&
        !isEmptyNumberInput(num_post_tiers) &&
        num_post_tiers < 2
      ) {
        return {
          message: "Fixed-tilt must have 2 or more posts in the tier dimension",
        };
      } else if (
        tracking_type === 1 &&
        !isEmptyNumberInput(num_post_tiers) &&
        num_post_tiers !== 1
      ) {
        return {
          message: "Single axis tracking must have a single tier of posts",
        };
      }
    },
  },
  in_plane_racking_enabled: {
    labelText: parameterInfo.purlins.display_name,
    helpText: parameterInfo.purlins.description,
  },
  in_plane_racking_dimensions: {
    labelText: parameterInfo.purlin_size.display_name,
    helpText: parameterInfo.purlin_size.description,
    disabledIf: ({ in_plane_racking_enabled }) => !in_plane_racking_enabled,
  },
  "in_plane_racking_dimensions.0": {
    labelText: "Width",
    unit: parameterInfo.purlin_size.units as Unit,
    disabledIf: ({ in_plane_racking_enabled }) => !in_plane_racking_enabled,
    helpText: parameterInfo.purlin_width.description,
  },
  "in_plane_racking_dimensions.1": {
    labelText: "Length",
    unit: parameterInfo.purlin_size.units as Unit,
    disabledIf: ({ in_plane_racking_enabled }) => !in_plane_racking_enabled,
    helpText: parameterInfo.purlin_length.description,
  },
  "in_plane_racking_dimensions.2": {
    labelText: "Depth",
    unit: parameterInfo.purlin_size.units as Unit,
    disabledIf: ({ in_plane_racking_enabled }) => !in_plane_racking_enabled,
    helpText: parameterInfo.purlin_depth.description,
  },
  in_plane_racking_shape: {
    labelText: parameterInfo.purlin_shape.display_name,
    helpText: parameterInfo.purlin_shape.description,
    disabledIf: ({ in_plane_racking_enabled }) => !in_plane_racking_enabled,
    options: [
      { value: MechanicalShapeEnum.Rect, label: "Rectangular" },
      {
        value: MechanicalShapeEnum.Round,
        label: "Round",
        disabledIf: () => true,
      },
    ],
  },
};
