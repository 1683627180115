import { TimeRange } from "@duet/shared/schemas/simulationDefinitions";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { compact, merge, uniq } from "lodash";
import { RootState, useAppSelector } from "~/redux/store";
import {
  getAllowedTimeRange,
  getEnviroDatasetType,
} from "../utils/meteoDataset";

export interface ProjectSimState {
  selectedTimeRange: (TimeRange & { enviroDatasetId: string }) | null;
  simulatedYearForTmy: number | null;
  enviroDatasetId: string | null;
  selectedSimBlockIds: string[];
}

const initialState: ProjectSimState = {
  selectedTimeRange: null,
  simulatedYearForTmy: null,
  enviroDatasetId: null,
  selectedSimBlockIds: [],
};

export const projectSimSlice = createSlice({
  name: "projectSim",
  initialState,
  reducers: {
    addSimBlockSelection(state, action: PayloadAction<string[] | string>) {
      state.selectedSimBlockIds = uniq(
        state.selectedSimBlockIds.concat(action.payload)
      );
    },
    removeSimBlockSelection(state, action: PayloadAction<string>) {
      state.selectedSimBlockIds = state.selectedSimBlockIds.filter(
        (id) => id !== action.payload
      );
    },
    clearSimBlockSelection(state) {
      state.selectedSimBlockIds = [];
    },
    setTimeRange(
      state,
      action: PayloadAction<{
        start: TimeRange["start"];
        end: TimeRange["end"];
        enviroDatasetId: string;
      }>
    ) {
      state.selectedTimeRange = merge(state.selectedTimeRange, action.payload);
    },
    setSimulatedYearForTmy(state, action: PayloadAction<number | null>) {
      state.simulatedYearForTmy = action.payload;
    },
    setEnviroDatasetId(state, action: PayloadAction<string | null>) {
      state.enviroDatasetId = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setTimeRange,
  setSimulatedYearForTmy,
  setEnviroDatasetId,
  addSimBlockSelection,
  removeSimBlockSelection,
  clearSimBlockSelection,
} = projectSimSlice.actions;

export const useProjectSimState = () => {
  return useAppSelector((state) => {
    return {
      ...state.projectSim,
      selectedSimBlocks: getSelectedSimBlocks(state),
      selectedSimBlockLayerIds: state.projectSim.selectedSimBlockIds
        .map((blockId) => state.projectBlock.blockLayerIdsByBlockId[blockId])
        .flat(),
      selectedEnviroDatasets: getSelectedEnviroDatasets(state),
      enviroDatasetType: getEnviroDatasetType(getSelectedEnviroDatasets(state)),
      allowedTimeRange: getAllowedTimeRange(getSelectedEnviroDatasets(state)),
    };
  });
};

function getSelectedEnviroDatasets(state: RootState) {
  return state.projectMeteo.projectEnviroDatasets.filter(
    ({ enviro_dataset }) =>
      state.projectSim.enviroDatasetId === enviro_dataset.id
    // TODO: support block-level meteo dataset ||  getSelectedSimBlocks(state).some((block) => block.enviro_dataset_id === enviro_dataset.id)
  );
}

function getSelectedSimBlocks(state: RootState) {
  return compact(
    state.projectSim.selectedSimBlockIds.map(
      (id) => state.projectBlock.blocksById[id]
    )
  );
}
