import {
  Avatar,
  Button,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import { TbChevronDown } from "react-icons/tb";
import { useAppUrls } from "~/appUrls";
import { useAuth, useIsSuperUser } from "~/features/auth/AuthContext";
import { useActiveUser } from "~/features/user/hooks";
import { RouterLink } from "../Link";

export function AppBarUserAvatar() {
  const auth = useAuth();
  const { user } = useActiveUser();
  const isSuperUser = useIsSuperUser();
  const fullName =
    user.first_name ?? user.last_name
      ? [user.first_name, user.last_name].join(" ")
      : user.email;
  const url = useAppUrls();

  return (
    <Menu isLazy>
      <MenuButton as={Button} variant="unstyled" p={2}>
        <HStack spacing={1}>
          <Avatar size="xs" name={fullName} />
          <Icon as={TbChevronDown} fontSize="xs" />
        </HStack>
      </MenuButton>
      <Portal>
        <MenuList
          boxShadow={"lg"}
          color="black"
          fontFamily={"body"}
          textTransform={"none"}
          zIndex={"dropdown"}
        >
          <MenuGroup title={fullName}>
            {isSuperUser && (
              <MenuItem as={RouterLink} to={url.adminUsers()}>
                System Admin
              </MenuItem>
            )}
            <MenuItem as={RouterLink} to={url.settingsUserAccountEdit()}>
              User Settings
            </MenuItem>
            <MenuDivider />
            <MenuItem
              as={Link}
              href={"https://enurgen.notion.site/enurgen-support?pvs=4"}
              target="_blank"
            >
              Documentation
            </MenuItem>
            <MenuItem
              as={Link}
              href="https://docs.google.com/forms/d/e/1FAIpQLScB44YHfOKVT5XXoVtkS8tlejaUTylI4Ct1iHPHS6rIIjhpRw/viewform?pli=1"
              target="_blank"
            >
              Contact Support
            </MenuItem>
            <MenuDivider />

            <MenuItem onClick={() => auth.logout()}>Logout</MenuItem>
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
}
