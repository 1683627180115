import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { configureStore, isAnyOf, isRejected } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { featureFlagSlice } from "~/features/featureFlag/featureFlagSlice";
import { organizationSlice } from "~/features/organization/organizationSlice";
import {
  createDataTag,
  projectCompareSlice,
  setCwd,
  setDataTags,
  setDatasets,
  updateDataset,
} from "~/features/projectCompare/reducers/projectCompareSlice";
import { projectBlockSlice } from "~/features/projectDesign/reducers/projectBlockSlice";
import { projectComponentSlice } from "~/features/projectDesign/reducers/projectComponentSlice";
import { projectDesignSlice } from "~/features/projectDesign/reducers/projectDesignSlice";
import { projectMeteoSlice } from "~/features/projectDesign/reducers/projectMeteoSlice";
import { projectSimSlice } from "~/features/projectDesign/reducers/projectSimSlice";
import { projectSlice } from "~/features/projectsCommon/projectSlice";
import { api } from "~/gql/generated";

/**
 * Log a warning and show a toast!
 */
const isLoggingOut = isAnyOf(
  api.endpoints.RefreshToken.matchRejected,
  api.endpoints.UserMe.matchRejected
);
const rtkQueryErrorLogger: Middleware =
  (apiMiddleware: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejected(action) && !isLoggingOut(action)) {
      if (action?.meta?.condition) {
        return next(action);
      }
    }

    return next(action);
  };

export const store = configureStore({
  reducer: {
    [featureFlagSlice.name]: featureFlagSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [projectDesignSlice.name]: projectDesignSlice.reducer,
    [projectBlockSlice.name]: projectBlockSlice.reducer,
    [projectComponentSlice.name]: projectComponentSlice.reducer,
    [projectMeteoSlice.name]: projectMeteoSlice.reducer,
    [projectSimSlice.name]: projectSimSlice.reducer,
    [api.reducerPath]: api.reducer,
    [projectCompareSlice.name]: projectCompareSlice.reducer,
    [organizationSlice.name]: organizationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [projectCompareSlice.name],
        ignoredActions: [
          setCwd.type,
          updateDataset.type,
          setDatasets.type,
          createDataTag.type,
          setDataTags.type,
        ],
      },
    })
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
