import {
  componentInputMetadata,
  inputMetadata,
} from "@duet/shared/pvComponents/inputMetadata";
import {
  ComponentType,
  acWireFormSchema,
  dcWireFormSchema,
  inverterFormSchema,
  panelFormSchema,
  tableFormSchema,
  transformerFormSchema,
} from "@duet/shared/pvComponents/projectParameters";
import {
  ComponentFormField,
  ComponentFormSchema,
  ComponentFormValue,
  ComponentInputMetadata,
  InputFieldMetadata,
  TopLevelComponentFormField,
} from "@duet/shared/pvComponents/types";
import { errorMap } from "@duet/shared/zod/errorMap";
import { get, keyBy } from "lodash";
import React from "react";
import z from "zod";
import { BlockParams } from "../blocks/blockModel";
import { AcWireSvgIcon } from "./icons/AcWireSvgIcon";
import { DcWireSvgIcon } from "./icons/DcWireSvgIcon";
import { InverterSvgIcon } from "./icons/InverterSvgIcon";
import { PanelSvgIcon } from "./icons/PanelSvgIcon";
import { TableSvgIcon } from "./icons/TableSvgIcon";
import { TransformerSvgIcon } from "./icons/TransformerSvgIcon";
import { ComponentFieldError } from "./types";

export * from "@duet/shared/pvComponents/inputMetadata";
export { ComponentType };

export const componentTypeMeta: {
  [T in ComponentType]: {
    icon: React.ReactElement;
    inputMetadata: ComponentInputMetadata<T>;
    formSchema: ComponentFormSchema<T>;
    graphQLTypeName: `Pv${Capitalize<ComponentType>}`;
    createFunctionName: `create${Capitalize<ComponentType>}`;
    updateFunctionName: `update${Capitalize<ComponentType>}`;
    displayName: string;
    blockRelationFieldKey?: keyof BlockParams;
  };
} = {
  [ComponentType.Panel]: {
    formSchema: panelFormSchema,
    inputMetadata: componentInputMetadata[ComponentType.Panel],
    icon: <PanelSvgIcon />,
    graphQLTypeName: "PvPanel",
    createFunctionName: "createPanel",
    updateFunctionName: "updatePanel",
    displayName: "Panel",
    blockRelationFieldKey: "pv_panel_id",
  },
  [ComponentType.Table]: {
    formSchema: tableFormSchema,
    inputMetadata: componentInputMetadata[ComponentType.Table],
    icon: <TableSvgIcon />,
    graphQLTypeName: "PvTable",
    createFunctionName: "createTable",
    updateFunctionName: "updateTable",
    displayName: "Table",
    blockRelationFieldKey: "pv_table_id",
  },
  [ComponentType.Inverter]: {
    formSchema: inverterFormSchema,
    inputMetadata: componentInputMetadata[ComponentType.Inverter],
    icon: <InverterSvgIcon />,
    graphQLTypeName: "PvInverter",
    createFunctionName: "createInverter",
    updateFunctionName: "updateInverter",
    displayName: "Inverter",
    blockRelationFieldKey: "pv_inverter_id",
  },
  [ComponentType.Transformer]: {
    formSchema: transformerFormSchema,
    inputMetadata: componentInputMetadata[ComponentType.Transformer],
    icon: <TransformerSvgIcon />,
    graphQLTypeName: "PvTransformer",
    createFunctionName: "createTransformer",
    updateFunctionName: "updateTransformer",
    displayName: "MV Transformer",
    blockRelationFieldKey: "pv_transformer_id",
  },
  [ComponentType.HvTransformer]: {
    formSchema: transformerFormSchema,
    inputMetadata: componentInputMetadata[ComponentType.HvTransformer],
    icon: <TransformerSvgIcon />,
    graphQLTypeName: "PvTransformer",
    createFunctionName: "createTransformer",
    updateFunctionName: "updateTransformer",
    displayName: "HV Transformer",
  },
  [ComponentType.DcWire]: {
    formSchema: dcWireFormSchema,
    inputMetadata: componentInputMetadata[ComponentType.DcWire],
    icon: <DcWireSvgIcon />,
    graphQLTypeName: "PvDcWire",
    createFunctionName: "createDcWire",
    updateFunctionName: "updateDcWire",
    displayName: "DC Wire",
    blockRelationFieldKey: "pv_dc_wire_id",
  },
  [ComponentType.AcWire]: {
    formSchema: acWireFormSchema,
    inputMetadata: componentInputMetadata[ComponentType.AcWire],
    icon: <AcWireSvgIcon />,
    graphQLTypeName: "PvAcWire",
    createFunctionName: "createAcWire",
    updateFunctionName: "updateAcWire",
    displayName: "AC Wire",
    blockRelationFieldKey: "pv_ac_wire_id",
  },
};

export const fullLabelText = (fieldName: ComponentFormField) => {
  const fullLabelText = get(inputMetadata, fieldName)?.fullLabelText;
  if (fullLabelText) {
    return fullLabelText;
  }

  const parts = fieldName.split(".");
  const topLevelFieldName = parts[0] as TopLevelComponentFormField;
  if (
    topLevelFieldName === "iam_profile" ||
    topLevelFieldName === "cec_efficiency_curves" ||
    topLevelFieldName === "temp_derate_curves"
  ) {
    return get(inputMetadata, topLevelFieldName)?.labelText;
  }

  if (parts.length === 1) {
    return get(inputMetadata, fieldName)?.labelText;
  }
  if (parts.length === 2) {
    return `${get(inputMetadata, topLevelFieldName)?.labelText} - ${
      get(inputMetadata, fieldName)?.labelText
    }`;
  }
};

export function validateComponentParameters<T extends ComponentType>(
  type: T,
  parameters: ComponentFormValue<T>
) {
  const { formSchema, inputMetadata } = componentTypeMeta[type];
  const result = formSchema.safeParse(parameters, { errorMap });

  let errors: ComponentFieldError[] = result.success
    ? []
    : result.error.issues.map((issue) => ({
        path: issue.path,
        message: issue.message,
        code: issue.code,
        received:
          "received" in issue ? (issue.received as z.ZodParsedType) : undefined,
        expected:
          "expected" in issue ? (issue.expected as z.ZodParsedType) : undefined,
      }));

  Object.entries<InputFieldMetadata<ComponentFormValue<T>>>(
    inputMetadata
  ).forEach(([fieldKey, data]) => {
    const error = data?.errorIf?.(parameters);
    if (error) {
      errors.push({
        path: fieldKey.split("."),
        message: error.message,
        code: "custom",
      });
    }
  });

  const errorPathsToClear = Object.entries<
    InputFieldMetadata<ComponentFormValue<T>>
  >(inputMetadata).flatMap(([fieldKey, data]) => {
    if (data?.disabledIf?.(parameters) || data?.hideIf?.(parameters)) {
      return [fieldKey as ComponentFormField];
    } else {
      return [];
    }
  });

  errors = errors.filter(
    ({ path }) =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      !errorPathsToClear.includes(path.join(".") as ComponentFormField)
  );

  return {
    hasFieldErrors: errors.length > 0,
    fieldErrors: keyBy(errors, (error) => error.path.join(".")),
    errorFields: errors.map(
      (error) => error.path[0] as TopLevelComponentFormField
    ),
  };
}

export function isFieldIncomplete(fieldError: ComponentFieldError) {
  return (
    fieldError.code === "invalid_type" &&
    (["undefined", "null"] as Array<string | undefined>).includes(
      fieldError.received
    ) &&
    !(["undefined", "null"] as Array<string | undefined>).includes(
      fieldError.expected
    )
  );
}
