/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  calcArrayAlignment,
  calcRect,
  flipBearing,
} from "@duet/shared/blockGeometry/guideLine";
import { rectangleGrid } from "@duet/shared/blockGeometry/rectangleGrid";
import {
  Anchor,
  Anchors,
  EMPTY_FEATURE_COLLECTION,
  SlopeAndApectProps,
  calcSlopeAndAspectForFeatures,
  computeAnchorsAndSides,
  isPolygon,
  solarAzimuthToBearing,
  translatePointXY,
} from "@duet/shared/geo";
import {
  Block,
  calculateBlockSectionTableDimensions,
} from "@duet/shared/models/BlockModel";
import {
  PanelFormValue,
  TableFormValue,
} from "@duet/shared/pvComponents/types";
import { calculateBlockDimensionsFromPanelAndTable } from "@duet/shared/pvComponents/utils";
import { isClose } from "@duet/shared/utils/math";
import {
  BBox,
  Feature,
  FeatureCollection,
  Geometry,
  LineString,
  MultiPolygon,
  Point,
  Polygon,
  Properties,
  bbox,
  bboxPolygon,
  center,
  coordAll,
  destination,
  envelope,
  featureCollection,
  featureReduce,
  getCoord,
  transformScale,
  union,
} from "@turf/turf";
import { cloneDeep, isNil } from "lodash";

type BlockLayerFeatureCollection = typeof EMPTY_FEATURE_COLLECTION;

type BlockDimensions = ReturnType<typeof calculateBlockSectionTableDimensions>;

export type TerrainByDimension = "table" | "section" | "block";
export interface GetTerrainOptions {
  by?: TerrainByDimension;
  getElevation?: (lng: number, lat: number) => number;
}

export interface GeoReference {
  origin: [number, number];
  angle: number;
}

export interface BlockLayer {
  id: string;
  block_id?: string | null;
  project_id: string;
  type: string;
  name: string;
  geojson: Feature | FeatureCollection;
  source_type?: string | null;
  source_filename?: string | null;
  source_url?: string | null;
}

interface SectionLayer extends BlockLayer {
  type: "section";
  geojson: Feature<Polygon>;
}
export interface PerimeterLayer extends BlockLayer {
  type: "perimeter";
  geojson: Feature<Polygon>;
}
export interface GuideLineLayer extends BlockLayer {
  type: "guide_line";
  geojson: Feature<LineString>;
}
export interface FramesLayer extends BlockLayer {
  type: "frame";
  geojson: FeatureCollection<Polygon>;
}
export interface SceneLayer extends BlockLayer {
  type: "scene";
  geojson: Feature<Point, { georeference: GeoReference }>;
  source_url: string;
}
export interface ShadingLayer<
  G extends Geometry = LineString | Polygon,
  P extends Properties = Properties,
> extends BlockLayer {
  type: "shading";
  geojson: Feature<G, P>;
}

export interface HeightLineProperties {
  pointHeights?: Record<number, number>;
}

export interface ExtrusionProperties {
  height?: number;
}

export const IGNORED_LAYER_TYPES_FOR_DISPLAY = ["scene", "shading"];
export const IGNORED_LAYER_TYPES_FOR_SELECTION = ["scene", "frame"];

export class BlockLayersModel {
  block: Block;
  #layers: BlockLayer[];
  perimeterLayers: PerimeterLayer[];
  sectionLayers: SectionLayer[];
  guideLineLayers: GuideLineLayer[];
  anchors: Anchors;
  dimensions: Record<any, BlockDimensions>;
  sectionLayerModels: BlockSectionLayerModel[];
  guideLineLayerModels: BlockAlignmentLayerModel[];
  frames: Array<Feature<Polygon>>;

  constructor(
    block: Block,
    layers: BlockLayer[],
    frames: Array<Feature<Polygon>> = []
  ) {
    this.block = block;
    this.#layers = layers;
    this.perimeterLayers = [];
    this.sectionLayers = [];
    this.guideLineLayers = [];
    this.frames = frames;
    this.#partitionLayers();

    if (layers.length === 0) {
      throw new Error("BlockLayersModel must have at least 1 layer");
    }
    const { anchors } = computeAnchorsAndSides(
      featureCollection([...this.#layers.flatMap(getFeaturesForBlockLayer)])
    );

    this.anchors = anchors;

    const oldDimensions = calculateBlockSectionTableDimensions(block);
    const newDimensions =
      block.pv_panel && block.pv_table
        ? (calculateBlockDimensionsFromPanelAndTable(
            block.pv_panel as PanelFormValue,
            block.pv_table as TableFormValue
          ) as BlockDimensions)
        : null;

    this.dimensions = {
      v0: oldDimensions,
      v1: newDimensions,
    };

    this.sectionLayerModels = this.sectionLayers.map(
      (layer) => new BlockSectionLayerModel(layer, this.dimensions)
    );

    this.guideLineLayerModels = this.guideLineLayers.reduce<
      BlockAlignmentLayerModel[]
    >((acc, layer) => {
      const perimeters = this.perimeterLayers.filter(
        (pl) => (pl.geojson as Feature).properties?.guideLineId === layer.id
      );

      if (perimeters.length === 0) return acc;
      acc.push(
        new BlockAlignmentLayerModel(layer, perimeters, this.dimensions)
      );
      return acc;
    }, []);
  }

  #partitionLayers() {
    for (const layer of this.#layers) {
      if (layer.type === "perimeter") {
        this.perimeterLayers.push(layer as PerimeterLayer);
      }
      if (layer.type === "section") {
        this.sectionLayers.push(layer as SectionLayer);
      }

      if (layer.type === "guide_line") {
        this.guideLineLayers.push(layer as GuideLineLayer);
      }
    }
  }

  get sectionsPerimeter(): Feature<MultiPolygon | Polygon> {
    const allSectionGeojson = this.sectionLayers.map((l) => l.geojson);
    return featureReduce(featureCollection(allSectionGeojson), (prev, cur) => {
      const poly = union(prev as Feature<Polygon>, cur);
      if (!poly) return prev;
      return poly;
    });
  }

  getAllTerrainCoords() {
    return [
      ...this.sectionLayers.flatMap((l) => coordAll(l.geojson)),
      ...this.guideLineLayerModels
        .flatMap((m) => m.perimeterLayers)
        .flatMap((l) => coordAll(l.geojson)),
      ...this.frames.flatMap((f) => coordAll(f)),
    ];
  }

  getTerrain({ by = "table", getElevation }: GetTerrainOptions) {
    let collection: FeatureCollection<Polygon> = featureCollection([]);
    const framePolygons = this.frames.map((frame, i) => ({
      ...frame,
      properties: {
        ...frame.properties,
        labelId: `Frame #${i}`,
      },
    }));

    if (by === "table") {
      const sectionGrids = this.sectionLayerModels.flatMap((m) => {
        return m.getGrid().features.map((f) => ({
          ...f,
          properties: {
            labelId: `${m.layer.name}: ${f.properties!.row}, ${f.properties!.column}`,
          },
        }));
      });

      const guideLineGrids = this.guideLineLayerModels.flatMap((m) => {
        return m.calcArrayAlignment().rects.features.map((f, idx) => ({
          ...f,
          properties: {
            labelId: `${m.guideLinelayer.name}: ${idx}`,
          },
        }));
      });

      collection = featureCollection([
        ...sectionGrids,
        ...guideLineGrids,
        ...framePolygons,
      ]);
    }

    if (by === "section" || by === "block") {
      const sectionPolygons = this.sectionLayers.map((l) => {
        const geojson = cloneDeep(l.geojson);

        return {
          ...geojson,
          properties: {
            labelId: l.name,
          },
        };
      });

      const pvPolygons = this.guideLineLayerModels.flatMap((m) => {
        return m.perimeterLayers.map((l) => {
          const geojson = cloneDeep(l.geojson);

          return {
            ...geojson,
            properties: {
              labelId: l.name,
            },
          };
        });
      });

      collection = featureCollection([
        ...sectionPolygons,
        ...pvPolygons,
        ...framePolygons,
      ]);

      if (by === "block") {
        collection = featureCollection([
          {
            ...envelope(collection),
            properties: {
              labelId: this.block.name,
            },
          },
        ]);
      }
    }

    const {
      collection: terrain,
      avgAspect,
      avgSlope,
    } = calcSlopeAndAspectForFeatures(collection, getElevation);

    return {
      terrain: terrain as TerrainFeatures,
      avgSlope,
      avgAspect,
    };
  }
}

export type TerrainFeatures = FeatureCollection<
  Polygon,
  {
    labelId: string;
    column?: number;
    row?: number;
  } & SlopeAndApectProps
>;

const SECTION_PERIMETER_SCALING_BUFFER = 1.001;

export class BlockSectionLayerModel {
  layer: SectionLayer;
  grid: ReturnType<typeof rectangleGrid> | BlockLayerFeatureCollection;
  dimensions: ReturnType<typeof calculateBlockSectionTableDimensions>;
  tables: BlockLayerFeatureCollection;
  anchors: Anchors;

  constructor(
    layer: SectionLayer,
    blockModelDimensions: Record<any, BlockDimensions>
  ) {
    if (layer.type !== "section") {
      throw new Error(`expected layer.type === "section", got: ${layer.type}`);
    }

    const geoJsonProps = layer.geojson?.properties ?? {};

    const hasGridCellDims =
      "gridCellWidth" in geoJsonProps && "gridCellHeight" in geoJsonProps;

    if (!hasGridCellDims) {
      throw new Error(
        "expected layer to have gridCellWidth and gridCellHeight"
      );
    }

    this.dimensions =
      blockModelDimensions[geoJsonProps.dimensionCalcVersion] ||
      blockModelDimensions.v0;
    this.layer = layer;
    this.grid = EMPTY_FEATURE_COLLECTION;
    this.tables = EMPTY_FEATURE_COLLECTION;

    const { anchors } = computeAnchorsAndSides(this.layer.geojson);

    this.anchors = anchors;
  }

  getGrid() {
    if (this.grid !== EMPTY_FEATURE_COLLECTION) return this.grid;
    const geojson = this.layer.geojson;
    if (
      !(
        geojson?.properties?.gridCellWidth &&
        geojson?.properties?.gridCellHeight
      )
    ) {
      return EMPTY_FEATURE_COLLECTION;
    }

    const grid = rectangleGrid(
      bbox(geojson),
      geojson.properties.gridCellWidth,
      geojson.properties.gridCellHeight,
      {
        units: "meters",
        mask: geojson,
        maskStrategy: "intersect",
        origin: geojson.properties.origin,
      }
    );

    this.grid = grid;
    return grid;
  }

  getTables() {
    const dimensions = this.dimensions;
    if (!dimensions) return EMPTY_FEATURE_COLLECTION;

    if (this.tables !== EMPTY_FEATURE_COLLECTION) return this.tables;

    let { tableHeight, tableWidth, azimuth } = dimensions;

    if (Math.abs(azimuth) === 0 || Math.abs(azimuth) === 180) {
      tableWidth = dimensions.tableHeight;
      tableHeight = dimensions.tableWidth;
    }

    const tables = this.grid.features.map((f) => {
      const p0 = center(f);
      const c0 = translatePointXY(p0, [tableHeight * 0.5, tableWidth * -0.5]);
      const c1 = translatePointXY(p0, [tableHeight * -0.5, tableWidth * 0.5]);
      return bboxPolygon([...getCoord(c0), ...getCoord(c1)] as BBox);
    });

    if (tables.length === 0) {
      return EMPTY_FEATURE_COLLECTION;
    }

    this.tables = featureCollection(tables) as BlockLayerFeatureCollection;

    return this.tables;
  }

  getStats() {
    const azimuth = Math.abs(this.dimensions?.azimuth || 0);

    let rows = this.grid.properties.maxColumns;

    if (azimuth === 0 || azimuth === 180) {
      rows = this.grid.properties.maxRows;
    }

    return {
      tables: this.tables.features.length,
      rows,
    };
  }

  hasConflict() {
    const layerGridDims = this.layer.geojson?.properties;
    const azimuth = this.dimensions?.azimuth;
    const currentGridDims = this.dimensions?.bboxDimensions;

    if (!layerGridDims || !currentGridDims || azimuth === undefined) {
      return null;
    }

    if (Math.abs(azimuth) === 90) {
      return (
        !isClose(layerGridDims.gridCellWidth, currentGridDims.height) ||
        !isClose(layerGridDims.gridCellHeight, currentGridDims.width)
      );
    }

    return (
      !isClose(layerGridDims.gridCellWidth, currentGridDims.width) ||
      !isClose(layerGridDims.gridCellHeight, currentGridDims.height)
    );
  }

  getProposedGeoJson(fromOrigin?: Anchor) {
    const dimensions = this.dimensions;
    if (!dimensions) {
      throw new Error("getProposedGeoJson required dimensions to be set");
    }

    const layer = this.layer;
    const origin =
      fromOrigin ?? layer.geojson?.properties?.origin ?? Anchor.center;
    const _bbox = bbox(layer.geojson);

    let { width, height } = dimensions.bboxDimensions;

    if (Math.abs(dimensions.azimuth) === 90) {
      width = dimensions.bboxDimensions.height;
      height = dimensions.bboxDimensions.width;
    }

    const grid = rectangleGrid(_bbox, width, height, {
      units: "meters",
      // scale the mask up slightly so the edges of the polygons do not intersect
      // masking works based on a geometry being contained, meaning edges cannot intersect
      mask: transformScale(layer.geojson, SECTION_PERIMETER_SCALING_BUFFER),
      origin,
    });

    const gridUnion = featureReduce(grid, (prev, cur) => {
      const poly = union(prev as Feature<Polygon>, cur);
      if (!poly) return prev;
      return poly;
    });

    if (!isPolygon(gridUnion)) {
      return {
        grid: null,
        boundary: null,
      };
    }

    // boundary will become the new layer geometry if a user accepts the proposed geometry
    const boundary = transformScale(
      gridUnion,
      SECTION_PERIMETER_SCALING_BUFFER
    );

    if (!boundary.properties) {
      boundary.properties = {};
    }

    Object.assign(boundary.properties, {
      id: layer.id,
      gridCellWidth: width,
      gridCellHeight: height,
    });

    return {
      grid,
      boundary,
    };
  }
}

export class BlockAlignmentLayerModel {
  guideLinelayer: GuideLineLayer;
  perimeterLayers: PerimeterLayer[];
  dimensions: ReturnType<typeof calculateBlockSectionTableDimensions>;
  id: string;

  constructor(
    guideLinelayer: GuideLineLayer,
    perimeterLayers: PerimeterLayer[],
    blockModelDimensions: Record<any, BlockDimensions>
  ) {
    this.id = guideLinelayer.id;
    this.guideLinelayer = guideLinelayer;
    this.perimeterLayers = perimeterLayers;
    this.dimensions = blockModelDimensions.v1;
  }

  isValidGuideLine() {
    const lineSpacing = this.dimensions?.rowSpacing;
    const azimuth = this.dimensions?.azimuth;
    const rectWidth = this.dimensions?.bboxDimensions.width;
    const rectHeight = this.dimensions?.bboxDimensions.height;
    const tableWidth = this.dimensions?.tableWidth;
    const tableHeight = this.dimensions?.tableHeight;

    if (
      this.perimeterLayers.length === 0 ||
      isNil(lineSpacing) ||
      isNil(azimuth) ||
      isNil(rectWidth) ||
      isNil(rectHeight) ||
      isNil(tableWidth) ||
      isNil(tableHeight) ||
      isNil(rectWidth) ||
      isNil(rectHeight)
    ) {
      return false;
    }

    return true;
  }

  calcArrayAlignment(
    updatedPoly?: Feature<Polygon>,
    updatedLine?: Feature<LineString>,
    { skipTables } = { skipTables: false }
  ) {
    if (!this.isValidGuideLine()) {
      throw new Error(
        "calcArrayAlignment requires poly, lineSpacing, azimuth, rectWidth, rectHeight"
      );
    }

    // isValidGuideLine will assert that dimensions and perimeters are not null
    // todo: support multiple polygons
    const [poly] = this.perimeterLayers.map((l) => l.geojson);
    const lineSpacing = this.dimensions!.rowSpacing;
    const azimuth = this.dimensions!.azimuth;
    const rectWidth = this.dimensions!.bboxDimensions.width;
    const rectHeight = this.dimensions!.bboxDimensions.height;
    const tableWidth = this.dimensions!.tableWidth;
    const tableHeight = this.dimensions!.tableHeight;

    const arrayAlignment = calcArrayAlignment(
      updatedPoly ?? poly,
      updatedLine ?? this.guideLinelayer.geojson,
      {
        lineSpacing,
        azimuth,
        rectWidth,
        rectHeight,
      }
    );

    const tables = featureCollection<Polygon>([]);

    if (!skipTables) {
      const bearing = solarAzimuthToBearing(azimuth);
      for (const f of arrayAlignment.rects.features) {
        const p0 = destination(
          center(f),
          tableHeight * 0.5,
          flipBearing(bearing),
          {
            units: "meters",
          }
        );

        tables.features.push(calcRect(p0, bearing, tableHeight, tableWidth));
      }
    }
    return {
      tables,
      ...arrayAlignment,
    };
  }
}

export function getFeaturesForBlockLayer(blockLayer: BlockLayer): Feature[] {
  if (!blockLayer.geojson) {
    return [];
  }

  return blockLayer.geojson.type === "FeatureCollection"
    ? blockLayer.geojson.features
    : [blockLayer.geojson];
}

export function isHeightLineLayer(
  layer: BlockLayer
): layer is ShadingLayer<LineString, HeightLineProperties> {
  return (
    layer.type === "shading" &&
    layer.geojson.type === "Feature" &&
    layer.geojson.geometry.type === "LineString"
  );
}

export function isExtrudedPolygonLayer(
  layer: BlockLayer
): layer is ShadingLayer<Polygon, ExtrusionProperties> {
  return (
    layer.type === "shading" &&
    layer.geojson.type === "Feature" &&
    layer.geojson.geometry.type === "Polygon"
  );
}
