// todo: mv these functions into standalone files

import { tc } from "@duet/ui/theme/color";
import * as turf from "@turf/turf";
import { findMedian } from "../utils";

export const EMPTY_FEATURE_COLLECTION: turf.FeatureCollection<any> & {
  properties: any;
} = {
  type: "FeatureCollection",
  features: [],
  properties: {},
};

export interface Sides {
  n: turf.Feature<turf.LineString>;
  e: turf.Feature<turf.LineString>;
  s: turf.Feature<turf.LineString>;
  w: turf.Feature<turf.LineString>;
}

export enum Anchor {
  center = "center",
  nw = "nw",
  n = "n",
  ne = "ne",
  e = "e",
  se = "se",
  s = "s",
  sw = "sw",
  w = "w",
}
export interface Anchors {
  ne: turf.Position;
  nw: turf.Position;
  sw: turf.Position;
  se: turf.Position;
  n: turf.Position;
  e: turf.Position;
  s: turf.Position;
  w: turf.Position;
  center: turf.Position;
}

export function computeAnchorsAndSides(geojson: turf.AllGeoJSON): {
  anchors: Anchors;
  sides: Sides;
} {
  const [minX, minY, maxX, maxY] = turf.bbox(geojson);

  const bounds: Record<string, turf.Position> = {
    ne: [maxX, maxY],
    nw: [minX, maxY],
    sw: [minX, minY],
    se: [maxX, minY],
  };

  const polygon = isPolygon(geojson)
    ? geojson
    : turf.bboxPolygon([minX, minY, maxX, maxY]);

  const line = turf.polygonToLine(polygon) as turf.Feature<turf.LineString>;

  const anchors: Partial<Anchors> = {
    ne: undefined,
    nw: undefined,
    sw: undefined,
    se: undefined,
  };

  turf.coordEach(line, (coord) => {
    Object.entries(anchors).forEach(([key, val]) => {
      const _key = key as keyof Anchors;
      if (!val) anchors[_key] = coord;

      if (
        turf.distance(turf.point(coord), turf.point(bounds[key])) <
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        turf.distance(turf.point(anchors[_key]!), turf.point(bounds[_key]!))
      ) {
        anchors[_key] = coord;
      }
    });
  });

  const sides: Sides = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    n: turf.lineSlice(anchors.ne!, anchors.nw!, turf.rewind(line)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    e: turf.lineSlice(anchors.ne!, anchors.se!, line),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    s: turf.lineSlice(anchors.sw!, anchors.se!, line),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    w: turf.lineSlice(anchors.nw!, anchors.sw!, turf.rewind(line)),
  };

  anchors.n = turf.getCoord(turf.center(sides.n));
  anchors.e = turf.getCoord(turf.center(sides.e));
  anchors.s = turf.getCoord(turf.center(sides.s));
  anchors.w = turf.getCoord(turf.center(sides.w));
  anchors.center = turf.getCoord(turf.center(polygon));

  const _anchors = anchors as Anchors;

  return { anchors: _anchors, sides };
}

export function anchorsByDistance(
  point: turf.helpers.Feature<turf.Point>,
  anchors: Anchors
): Array<[keyof Anchors, turf.Position, number]> {
  const distances = Object.entries(anchors).map(([key, val]) => {
    return [
      key,
      val,
      turf.distance(point, turf.point(val), {
        units: "meters",
      }),
    ];
  });

  distances.sort((a, b) => a[2] - b[2]);

  return distances as Array<[keyof Anchors, turf.Position, number]>;
}

export function translatePoint(
  point: turf.helpers.Feature<turf.Point>,
  meters: number,
  deg: number
) {
  return turf.transformTranslate(point, meters, deg, {
    units: "meters",
  });
}

export function translatePointXY(
  point: turf.helpers.Feature<turf.Point>,
  [xOffsetMeters = 0, yOffsetMeters = 0]: [number, number],
  rotation: number = 0
) {
  return translatePoint(
    translatePoint(point, yOffsetMeters, 0),
    xOffsetMeters,
    90 + rotation
  );
}

export function coordToLngLatLit(coord: number[]) {
  return {
    lng: coord[0],
    lat: coord[1],
  };
}

export function computePolygonAttributes(polygon: turf.Feature<turf.Polygon>) {
  const bbox = turf.bbox(polygon);
  const center = turf.center(polygon);
  const { anchors, sides } = computeAnchorsAndSides(polygon);
  return { bbox, center, anchors, sides };
}

export function featureCollectionMap<
  T extends (feature: turf.Feature<any>, idx: number) => any,
>(featureCollection: turf.FeatureCollection<any>, fn: T) {
  return turf.featureCollection(featureCollection.features.map(fn));
}

export function isPolygon(
  feature: any = {}
): feature is turf.Feature<turf.Polygon> {
  return (
    feature?.geometry?.type === "Polygon" &&
    feature.geometry.coordinates?.[0].length > 1
  );
}

export function isLineString(
  feature: any = {}
): feature is turf.Feature<turf.LineString> {
  return (
    feature?.geometry?.type === "LineString" &&
    feature.geometry.coordinates.length >= 2
  );
}

export function isPoint(
  feature: any = {}
): feature is turf.Feature<turf.Point> {
  return (
    feature?.geometry?.type === "Point" &&
    feature.geometry.coordinates.length === 2
  );
}

export function calcSlopeAndAspect(
  geojson: turf.Feature<turf.Polygon>,
  getElevation: (lng: number, lat: number) => number
) {
  const { anchors } = computeAnchorsAndSides(geojson);

  const { n, s, e, w } = anchors;

  const eleN = getElevation(n[0], n[1]);
  const eleS = getElevation(s[0], s[1]);
  const eleE = getElevation(e[0], e[1]);
  const eleW = getElevation(w[0], w[1]);

  const dx = turf.distance(e, w, {
    units: "meters",
  });
  const dy = turf.distance(n, s, {
    units: "meters",
  });

  // primarily taken from this: https://github.com/slutske22/leaflet-topography/blob/main/src/getTopography.ts
  // also see:
  // https://observablehq.com/@slutske22/slope-as-a-function-of-latlng-in-leaflet
  // https://pro.arcgis.com/en/pro-app/latest/tool-reference/spatial-analyst/how-slope-works.htm
  // https://www.esri.com/arcgis-blog/products/imagery/imagery/new-aspect-slope-raster-function-now-available/
  const dzdx = (eleW - eleE) / dx;
  const dzdy = (eleN - eleS) / dy;

  const riseRun = Math.atan(Math.sqrt(dzdx ** 2 + dzdy ** 2));
  const slope = riseRun * (180 / Math.PI);
  const aspectPolar =
    dx !== 0
      ? (Math.atan2(dzdy, dzdx) * (180 / Math.PI) + 180) % 360
      : (90 * (dy > 0 ? 1 : -1) + 180) % 360;

  const aspect = polarToBearing(aspectPolar);

  return { slope, aspect, riseRun };
}

function polarToBearing(polar: number) {
  let bearing = polar - 90;
  if (bearing < 0) {
    bearing += 360;
  }
  return bearing;
}

export interface SlopeAndApectProps {
  aspect: number;
  slope: number;
  riseRun: number;
  color: string;
}

export type SlopeAspectFeatureCollection = turf.FeatureCollection<
  turf.Polygon,
  SlopeAndApectProps
>;

export function calcSlopeAndAspectForFeatures(
  collection: turf.FeatureCollection<turf.Polygon>,
  getElevation: ((lng: number, lat: number) => number) | undefined
) {
  let sumSlope = 0;
  let sumAspect = 0;

  for (const feature of collection.features) {
    if (!feature.properties) feature.properties = {};

    if (!getElevation) {
      feature.properties.color = "rgb(0,0,0)";
      continue;
    }

    const slopeAspect = calcSlopeAndAspect(feature, getElevation);

    const { slope, aspect, riseRun } = slopeAspect;

    Object.assign(feature.properties, {
      slope,
      aspect,
      riseRun,
      azimuth: bearingToDuetAzimuth(aspect),
      color: slopeAspectColor(slopeAspect).toRgbString(),
    });

    sumSlope += slope;
    sumAspect += aspect;
  }

  const avgSlope = sumSlope / collection.features.length;
  const avgAspect = sumAspect / collection.features.length;

  return {
    collection: collection as SlopeAspectFeatureCollection,
    avgSlope,
    avgAspect,
  };
}

export const nMin = 337.5;
export const neMin = 22.5;
export const eMin = 67.5;
export const seMin = 112.5;
export const sMin = 157.5;
export const swMin = 202.5;
export const wMin = 247.5;
export const nwMin = 292.5;

export const slopeSaturationBins = [
  {
    threshold: 0.1,
    saturation: 0,
  },
  {
    threshold: 0.05,
    saturation: 0.33,
  },
  {
    threshold: 0.025,
    saturation: 0.66,
  },
  {
    threshold: 0,
    saturation: 1,
  },
] as const;

export function slopeAspectColor({
  aspect,
  riseRun,
}: ReturnType<typeof calcSlopeAndAspect>) {
  // north
  let color = tc("#84d602");

  if (aspect >= nMin && aspect < neMin) {
    color = tc("#84d602");
  }
  // north east
  if (aspect >= neMin && aspect < eMin) {
    color = tc("#00ab44");
  }
  // east
  if (aspect >= eMin && aspect < seMin) {
    color = tc("#0068c0");
  }
  // south east
  if (aspect >= seMin && aspect < sMin) {
    color = tc("#6c1aa3");
  }
  // south
  if (aspect >= sMin && aspect < swMin) {
    color = tc("#ca1c9c");
  }
  // south west
  if (aspect >= swMin && aspect < wMin) {
    color = tc("#ff5568");
  }
  // west
  if (aspect >= wMin && aspect < nwMin) {
    color = tc("#ffab48");
  }
  // north west
  if (aspect >= nwMin && aspect < nMin) {
    color = tc("#f4fa00");
  }

  const saturation =
    slopeSaturationBins.find((s) => riseRun >= s.threshold)?.saturation ?? 0;

  if (saturation > 0) {
    color.desaturate(saturation * 100).brighten(saturation * 33);
  }

  return color;
}

export function bearingToCardinal(bearing: number) {
  if (bearing >= nMin && bearing < neMin) {
    return "N";
  }
  if (bearing >= neMin && bearing < eMin) {
    return "NE";
  }
  if (bearing >= eMin && bearing < seMin) {
    return "E";
  }
  if (bearing >= seMin && bearing < sMin) {
    return "SE";
  }
  if (bearing >= sMin && bearing < swMin) {
    return "S";
  }
  if (bearing >= swMin && bearing < wMin) {
    return "SW";
  }
  if (bearing >= wMin && bearing < nwMin) {
    return "W";
  }
  if (bearing >= nwMin && bearing < nMin) {
    return "NW";
  }
  return "N";
}

// todo: these are exactly the same, need to clean this up
export function bearingToDuetAzimuth(bearing: number) {
  return 180 - bearing;
}
export function solarAzimuthToBearing(bearing: number) {
  return 180 - bearing;
}
export function invertAzimuth(azimuth: number) {
  return (180 - Math.abs(azimuth)) * -Math.sign(azimuth);
}

export function getCenterCoordsForBoundingBox(
  bbox: [number, number, number, number]
) {
  const [minX, minY, maxX, maxY] = bbox;
  const [longitude, latitude] = [(minX + maxX) / 2, (minY + maxY) / 2];

  return { longitude, latitude };
}

export function getCenterCoordsForFeatures(
  features: turf.Feature[] | turf.FeatureCollection
) {
  const collection = Array.isArray(features)
    ? turf.featureCollection(features)
    : features;

  const [longitude, latitude] = turf.getCoord(turf.center(collection));
  return { longitude, latitude };
}

/**
 * Get the angle by makking unit vectors for the given set of angles
 * and measuring the angle of a vector with the median [x, y] values.
 */
export function medianAngle(angles: number[]) {
  const aggregates = angles.reduce<{ x: number[]; y: number[] }>(
    (acc, angle) => {
      angle = turf.degreesToRadians(angle);
      acc.x.push(Math.cos(angle));
      acc.y.push(Math.sin(angle));
      return acc;
    },
    { x: [], y: [] }
  );

  const median = {
    x: findMedian(aggregates.x),
    y: findMedian(aggregates.y),
  };

  if (median.x === undefined || median.y === undefined) {
    return undefined;
  }

  return turf.radiansToDegrees(Math.atan2(median.y, median.x));
}

export function compareAngles(fromAngle: number, toAngle: number) {
  fromAngle = turf.degreesToRadians(fromAngle);
  toAngle = turf.degreesToRadians(toAngle);

  const origin = [0, 0];
  const from = [Math.cos(fromAngle), Math.sin(fromAngle)];
  const to = [Math.cos(toAngle), Math.sin(toAngle)];

  // turf.angle gives us the smallest positive angle so we'll have to
  // negate it if `from` -> `to` is counter-clockwise
  const sign = turf.booleanClockwise([from, origin, to]) ? 1 : -1;
  return sign * turf.angle(from, origin, to);
}

interface LineIntersection {
  t: number;
  feature: turf.Feature;
}

export function getLineIntersections(
  features: Array<turf.Feature<turf.LineString>>,
  line: turf.LineString
): LineIntersection[] {
  const start = line.coordinates[0];

  return features.reduce<LineIntersection[]>((acc, feature) => {
    const intersection = turf.lineIntersect(line, feature).features[0];
    if (intersection) {
      acc.push({
        t: turf.distance(start, intersection, { units: "meters" }),
        feature,
      });
    }

    return acc;
  }, []);
}
