import { isNil, padStart, range, sum } from "lodash";
import * as luxon from "luxon";

export const DateTimeFormatTMY = "LLLL d T";
export const DateTimeFormatSequential = "yyyy-LL-dd hh:mm a";

export const daysInMonth: readonly number[] = [
  31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
];
export const dayOptions = (month: number) => {
  return range(1, daysInMonth[month - 1] + 1);
};
export const monthOptions: readonly string[] = luxon.Info.months();
export const hours: readonly number[] = Array.from(Array(24).keys());
export const hourOptions = hours.map((_val, i) => {
  return `${padStart(String(i), 2, "0")}:00`;
});

export const toNDay = (month: number, day: number) => {
  return sum(daysInMonth.slice(0, month - 1)) + day;
};

export const toLuxonDate = (
  {
    year,
    month,
    day,
    hour,
    minute = 0,
  }: {
    year?: number | null;
    month: number;
    day: number;
    hour: number;
    minute?: number | null;
  },
  { ignoreYear }: { ignoreYear?: boolean } = {}
) => {
  return luxon.DateTime.fromObject({
    year: !ignoreYear && !isNil(year) && !isNaN(year) ? year : undefined,
    month: !isNaN(month) ? month : undefined,
    day: !isNaN(day) ? day : undefined,
    hour: !isNaN(hour) ? hour : undefined,
    minute: !isNil(minute) && !isNaN(minute) ? minute : undefined,
  });
};

export function isSameYear(
  timestamp1:
    | string
    | luxon.DateTime
    | { month: number; year?: number | undefined | null },
  timestamp2:
    | string
    | luxon.DateTime
    | { month: number; year?: number | undefined | null }
) {
  const date1 =
    typeof timestamp1 === "string"
      ? luxon.DateTime.fromISO(timestamp1, { zone: "utc" })
      : timestamp1;
  const date2 =
    typeof timestamp2 === "string"
      ? luxon.DateTime.fromISO(timestamp2, { zone: "utc" })
      : timestamp2;

  return date1.year === date2.year;
}

export function isSameMonth(
  timestamp1:
    | string
    | luxon.DateTime
    | { month: number; year?: number | undefined | null },
  timestamp2:
    | string
    | luxon.DateTime
    | { month: number; year?: number | undefined | null },
  enviroDatasetType: "TMY" | "Sequential"
) {
  const date1 =
    typeof timestamp1 === "string"
      ? luxon.DateTime.fromISO(timestamp1, { zone: "utc" })
      : timestamp1;
  const date2 =
    typeof timestamp2 === "string"
      ? luxon.DateTime.fromISO(timestamp2, { zone: "utc" })
      : timestamp2;
  return (
    date1.month === date2.month &&
    (date1.year === date2.year || enviroDatasetType === "TMY")
  );
}

export function isSameDay(
  timestamp1:
    | string
    | luxon.DateTime
    | { day: number; month: number; year?: number | undefined | null },
  timestamp2:
    | string
    | luxon.DateTime
    | { day: number; month: number; year?: number | undefined | null },
  enviroDatasetType: "TMY" | "Sequential"
) {
  const date1 =
    typeof timestamp1 === "string"
      ? luxon.DateTime.fromISO(timestamp1, { zone: "utc" })
      : timestamp1;
  const date2 =
    typeof timestamp2 === "string"
      ? luxon.DateTime.fromISO(timestamp2, { zone: "utc" })
      : timestamp2;

  return (
    date1.day === date2.day &&
    date1.month === date2.month &&
    (date1.year === date2.year || enviroDatasetType === "TMY")
  );
}
