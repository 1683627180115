import { TimeRange } from "@duet/shared/schemas/simulationDefinitions";
import {
  DateTimeFormatSequential,
  DateTimeFormatTMY,
  toLuxonDate,
} from "@duet/shared/utils";
import { isNumber, uniqBy } from "lodash";
import * as luxon from "luxon";
import {
  EnviroDatasetType,
  GetProjectEnviroDatasetsQuery,
} from "~/gql/generated";
import { BlockModel, blockInputMetadata } from "../blocks/blockModel";

export type ProjectEnviroDataset =
  GetProjectEnviroDatasetsQuery["projectEnviroDatasets"][number];

export const getEnviroDatasetName = (
  projectEnviroDatasets: ProjectEnviroDataset[],
  enviro_dataset_id: string | null | undefined
) => {
  const projectEnviroDataset = projectEnviroDatasets.find(
    ({ enviro_dataset }) => enviro_dataset.id === enviro_dataset_id
  );

  return (
    projectEnviroDataset?.name ?? projectEnviroDataset?.enviro_dataset.name
  );
};

export function getEnviroDatasetType(
  selectedEnviroDatasets: ProjectEnviroDataset[]
) {
  if (selectedEnviroDatasets.length === 0) {
    return undefined;
  }
  if (
    selectedEnviroDatasets.every(
      ({ enviro_dataset }) => enviro_dataset.type === EnviroDatasetType.Tmy
    )
  ) {
    return EnviroDatasetType.Tmy;
  } else if (
    selectedEnviroDatasets.every(
      ({ enviro_dataset }) =>
        enviro_dataset.type === EnviroDatasetType.Sequential
    )
  ) {
    return EnviroDatasetType.Sequential;
  } else {
    return undefined;
  }
}

export function getAllowedTimeRange(
  selectedEnviroDatasets: ProjectEnviroDataset[]
): { start: TimeRange["start"]; end: TimeRange["end"] } | undefined {
  if (selectedEnviroDatasets.length === 0) {
    return undefined;
  }
  if (
    uniqBy(selectedEnviroDatasets, ({ enviro_dataset }) => enviro_dataset.type)
      .length > 1
  ) {
    return undefined;
  }

  const enviroDatasetType = selectedEnviroDatasets[0].enviro_dataset.type;

  const luxonIntervals = selectedEnviroDatasets.map(({ enviro_dataset }) =>
    luxon.Interval.fromDateTimes(
      luxon.DateTime.fromISO(enviro_dataset.start_time, {
        zone: "utc",
      }),
      luxon.DateTime.fromISO(enviro_dataset.end_time, { zone: "utc" })
    )
  );
  let result = luxonIntervals[0];
  for (let i = 1; i < luxonIntervals.length; i++) {
    const combinedInterval = result.intersection(luxonIntervals[i]);
    if (!combinedInterval) {
      return undefined;
    }
    result = combinedInterval;
  }

  if (!result.start || !result.end) {
    return undefined;
  }

  return {
    start: {
      year:
        enviroDatasetType === EnviroDatasetType.Tmy
          ? undefined
          : result.start.year,
      month: result.start.month,
      day: result.start.day,
      hour: result.start.hour,
      minute: result.start.minute,
    },
    end: {
      year:
        enviroDatasetType === EnviroDatasetType.Tmy
          ? undefined
          : result.end.year,
      month: result.end.month,
      day: result.end.day,
      hour: result.end.hour,
      minute: result.end.minute,
    },
  };
}

export function getTimeRangeError(
  enviroDatasetType: EnviroDatasetType | undefined,
  selectedTimeRange: {
    start: TimeRange["start"];
    end: TimeRange["end"];
  } | null,
  allowedTimeRange:
    | { start: TimeRange["start"]; end: TimeRange["end"] }
    | undefined,
  selectedEnviroDatasets: ProjectEnviroDataset[],
  selectedSimBlocks: BlockModel[],
  simulatedYearForTmy: number | undefined | null
) {
  if (!enviroDatasetType && selectedEnviroDatasets.length > 0) {
    return "Meteo datasets of the selected blocks must have the same type";
  }
  const ignoreYear = enviroDatasetType === EnviroDatasetType.Tmy;
  if (
    selectedTimeRange &&
    toLuxonDate(selectedTimeRange.start, {
      ignoreYear,
    }) >
      toLuxonDate(selectedTimeRange.end, {
        ignoreYear,
      })
  ) {
    return "'To' must be no earlier than 'From'";
  }
  if (
    enviroDatasetType &&
    allowedTimeRange &&
    selectedTimeRange &&
    toLuxonDate(selectedTimeRange.start, { ignoreYear }) <
      toLuxonDate(allowedTimeRange.start, { ignoreYear })
  ) {
    return `'From' must be no earlier than the start time of the selected meteo datasets \n(${toLuxonDate(
      allowedTimeRange.start
    ).toFormat(
      enviroDatasetType === EnviroDatasetType.Tmy
        ? DateTimeFormatTMY
        : DateTimeFormatSequential
    )})`;
  }
  if (
    enviroDatasetType &&
    allowedTimeRange &&
    selectedTimeRange &&
    toLuxonDate(selectedTimeRange.end, { ignoreYear }) >
      toLuxonDate(allowedTimeRange.end, { ignoreYear })
  ) {
    return `'To' must be no later than the end time of the selected meteo datasets \n(${toLuxonDate(
      allowedTimeRange.end
    ).toFormat(
      enviroDatasetType === EnviroDatasetType.Tmy
        ? DateTimeFormatTMY
        : DateTimeFormatSequential
    )})`;
  }
  const pvSystemStartDates = selectedSimBlocks.flatMap((block) =>
    block.pv_system_start_date
      ? [luxon.DateTime.fromFormat(block.pv_system_start_date, "yyyy-MM-dd")]
      : []
  );
  if (enviroDatasetType && selectedTimeRange) {
    const year =
      enviroDatasetType === EnviroDatasetType.Tmy
        ? simulatedYearForTmy
        : selectedTimeRange.start.year;

    if (
      isNumber(year) &&
      pvSystemStartDates.some(
        (pvSystemStartDate) =>
          toLuxonDate({
            ...selectedTimeRange.start,
            year,
          }) < pvSystemStartDate
      )
    ) {
      return `'From' must be no earlier than the ${blockInputMetadata.pv_system_start_date.labelText} of selected blocks`;
    }
  }

  return undefined;
}
