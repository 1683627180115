import { useMemo } from "react";
import { Location, Path } from "react-router-dom";
import { useOrganization } from "./features/organization/useOrganization";

interface AppURLsInit {
  organizationSlug: string;
  searchParams?: Record<string, string | undefined>;
}
export class AppURLs {
  searchParams: URLSearchParams;
  organizationSlug: string;

  constructor({ organizationSlug, searchParams }: AppURLsInit) {
    this.searchParams = new URLSearchParams(
      AppURLs.cleanQuery(searchParams ?? {})
    );
    this.organizationSlug = organizationSlug;
  }

  setSearchParams(
    searchParams: ConstructorParameters<typeof URLSearchParams>[0]
  ) {
    this.searchParams = new URLSearchParams(searchParams);
    return this;
  }

  setOrganizationSlug(organizationSlug: string) {
    this.organizationSlug = organizationSlug;
    return this;
  }

  static cleanQuery(query: Record<string, string | undefined>) {
    return Object.entries(query ?? {}).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
  }

  private readonly route = (
    route: string,
    query?: Record<string, string | undefined>
  ) => {
    const validQuery = AppURLs.cleanQuery(query ?? {});

    const searchParams = query
      ? new URLSearchParams(validQuery)
      : this.searchParams;

    return makeRouteBuilder({
      pathname: route,
      search: searchParams.toString(),
      hash: "",
    });
  };

  adminFlags = () => {
    return this.route("/admin/flags");
  };

  adminUsers = () => {
    return this.route("/admin/users");
  };

  adminInviteUser = () => {
    return this.route("/admin/invite");
  };

  adminImpersonateUser = () => {
    return this.route("/admin/impersonate");
  };

  adminInverterDataset = () => {
    return this.route("/admin/inverter");
  };

  indexRoute = () => {
    return this.route("/");
  };

  homeRoute = () => {
    return this.organizationBaseRoute("/home");
  };

  loginRoute = (referer?: string, invite_id?: string) => {
    return this.route("/auth/login", {
      referer,
      invite_id,
    });
  };

  registerRoute = () => {
    return this.route("/auth/register");
  };

  passwordReset = () => {
    return this.route("/auth/password-reset");
  };

  invitationRegister = (token: string) => {
    return this.route(`/auth/invitation/${token}`);
  };

  emailVerification = () => {
    return this.route("/auth/email-verification");
  };

  private readonly organizationBaseRoute = (pathname: string) => {
    return this.route(`/${this.organizationSlug}${pathname}`);
  };

  private readonly projectBaseRoute = (projectId: string, pathname: string) => {
    return this.organizationBaseRoute(`/project/${projectId}${pathname}`);
  };

  projectCreateRoute = () => {
    return this.organizationBaseRoute("/project-create");
  };

  projectsRoute = () => {
    return this.organizationBaseRoute("/projects");
  };

  /**
   * @deprecated use homeRoute or projectsRoute instead
   * */
  projectOpenRoute = () => {
    return this.organizationBaseRoute("/project-open");
  };

  projectRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "");
  };

  projectDesignRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/design");
  };

  projectDesignBlockRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/design/blocks");
  };

  projectDesignComponentRoute = (
    projectId: string,
    componentType?: string,
    componentId?: string
  ) => {
    return this.projectBaseRoute(
      projectId,
      "/design/components" +
        (componentType ? `/${componentType}` : "") +
        (componentId ? `/${componentId}` : "")
    );
  };

  projectSimRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/compute");
  };

  projectSimBlocksRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/compute/blocks");
  };

  projectSimMeteoRoute = (projectId: string, enviroDatasetOwnerId: string) => {
    return this.projectBaseRoute(
      projectId,
      `/resources/meteo/${enviroDatasetOwnerId}`
    );
  };

  projectMeteoIndexRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/resources/meteo");
  };

  projectSimulationsIndexRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/simulations");
  };

  projectSimulationRoute(projectId: string, simulationId: string) {
    return this.projectBaseRoute(projectId, `/simulations/${simulationId}`);
  }

  projectSimulationsDetailRoute(
    projectId: string,
    simulationId: string,
    stage?: string
  ) {
    return this.projectBaseRoute(
      projectId,
      `/simulations/${simulationId}` + (stage ? `/${stage}` : "/energy-yields")
    );
  }

  projectCompareIndexRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/compare");
  };

  projectCompareWorkbookRoute = (projectId: string, workbookId: string) => {
    return this.projectBaseRoute(projectId, `/compare/${workbookId}`);
  };

  projectPlantPlayback = (projectId: string, id?: string) => {
    return this.projectBaseRoute(
      projectId,
      `/plant-playback${id ? `/${id}` : ""}`
    );
  };

  projectMonitorIndexRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor");
  };

  projectMonitorIssuesIndexRoute = (projectId: string, status: string) => {
    return this.projectBaseRoute(projectId, `/monitor/issues/${status}`);
  };

  projectMonitorBlockRoute = (
    projectId: string,
    blockId: string,
    component?: string
  ) => {
    return this.projectBaseRoute(
      projectId,
      `/monitor/block/${blockId}${component ? `/${component}` : ""}`
    );
  };

  projectMonitorSystemPerformanceRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/system-performance");
  };

  projectMonitorSystemLossesRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/system-losses");
  };

  projectMonitorMeteoRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/meteo");
  };

  projectMonitorModelPerformanceRoute = (projectId: string) => {
    return this.projectBaseRoute(projectId, "/monitor/model-performance");
  };

  settingsIndexPage = () => {
    return this.route("/settings");
  };

  settingsUserApiTokens = () => {
    return this.route("/settings/user/api-tokens");
  };

  settingsUserAccountEdit = () => {
    return this.route("/settings/user/account/edit");
  };

  settingsOrganizationEdit = () => {
    return this.organizationBaseRoute("/settings/edit");
  };

  settingsOrganizationProjects = () => {
    return this.organizationBaseRoute("/settings/organization-projects");
  };

  pv3Playground = (scene?: string) => {
    return this.route(`/pv3${scene ? `/${scene}` : ""}`);
  };

  onboardingIntakeForm = () => {
    return this.route("/onboarding/intake-form");
  };

  organizationPicker = () => {
    return this.route("/organization-picker");
  };
}

export function useAppUrls(appUrlsInit?: Partial<AppURLsInit>) {
  // purposefully not using useOrganization here as appUrls can be used outside of the OrganizationProvider
  const { organization } = useOrganization();
  return useMemo(
    () =>
      new AppURLs({
        organizationSlug: organization?.slug ?? "organization",
        ...appUrlsInit,
      }),
    [organization?.slug, appUrlsInit]
  );
}

function stripQuerystring(url: string) {
  return url.replace(/(\?.*)/g, "");
}

export function isPathnameActive(
  aUrl: string | Path,
  bUrl: string | Path = "",
  { startsWith } = { startsWith: false }
) {
  const a = typeof aUrl === "string" ? aUrl : aUrl.pathname;
  const b = typeof bUrl === "string" ? bUrl : bUrl.pathname;
  if (startsWith) {
    return stripQuerystring(a).startsWith(stripQuerystring(b));
  }
  return stripQuerystring(a) === stripQuerystring(b);
}

export function getRefererAndInviteId(location: Location) {
  const { pathname, search, hash } = location;

  const searchParams = new URLSearchParams(search);
  const invite_id = searchParams.get("invite_id") ?? undefined;
  searchParams.delete("invite_id");

  return {
    referer: `${pathname}${searchParams.toString()}${hash}`,
    invite_id,
  };
}

interface RouteBuilder extends Path {
  query: (params: Record<string, string | undefined>) => RouteBuilder;
  stripQuery: () => RouteBuilder;
  toString: () => string;
}

function makeRouteBuilder({ pathname, search, hash }: Path): RouteBuilder {
  return {
    pathname,
    search,
    hash,
    query(params: Record<string, string | undefined>) {
      const searchParams = new URLSearchParams(search);
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          searchParams.set(key, value);
        }
      });
      return makeRouteBuilder({
        pathname,
        search: `?${searchParams.toString()}`,
        hash,
      });
    },
    stripQuery() {
      return makeRouteBuilder({
        pathname,
        search: "",
        hash,
      });
    },
    toString() {
      return `${pathname}${search}${hash}`;
    },
  };
}
