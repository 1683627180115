import { createContext, useEffect, useMemo } from "react";
import { BackgroundTaskManager } from "./BackgroundTasks";

export const BackgroundTasksContext =
  createContext<BackgroundTaskManager | null>(null);

export function BackgroundTasksProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const backgroundTaskManager = useMemo(() => new BackgroundTaskManager(), []);

  useEffect(() => {
    // Prevent the user from closing the tab while there are running tasks
    function handleOnBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
    }

    backgroundTaskManager.on("taskStateChanged", () => {
      const hasRunning = Array.from(backgroundTaskManager.tasks).some(
        (task) => task.status === "running"
      );
      if (hasRunning) {
        window.addEventListener("beforeunload", handleOnBeforeUnload);
        return;
      }
      window.removeEventListener("beforeunload", handleOnBeforeUnload);
    });
  }, []);

  return (
    <BackgroundTasksContext.Provider value={backgroundTaskManager}>
      {children}
    </BackgroundTasksContext.Provider>
  );
}
