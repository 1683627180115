import { HStack, Stack } from "@chakra-ui/react";
import { enurgenDark } from "@duet/ui/theme/color";
import { ReactNode } from "react";
import { useAppUrls } from "~/appUrls";
import { AppBarItem } from ".";
import { AdminImpersonateAppBar } from "../../features/admin/AdminImpersonateAppBar";
import { DuetLogo } from "../EnurgenLogo/EnurgenLogo";
import { AppBarUserAvatar } from "./AppBarUserAvatar";

interface AppBarProps {
  children?: ReactNode;
}

// todo, no default exports
export default AuthenticatedAppBar;

export function AuthenticatedAppBar({ children }: AppBarProps) {
  return (
    <Stack spacing={0}>
      <AdminImpersonateAppBar />

      <AppBarBase
        rightElement={
          <HStack>
            <AppBarItem p={0}>
              <AppBarUserAvatar />
            </AppBarItem>
          </HStack>
        }
      >
        {children}
      </AppBarBase>
    </Stack>
  );
}

interface AppBarBaseProps {
  children?: ReactNode;
  rightElement?: ReactNode;
}

export function AppBarBase({ children, rightElement }: AppBarBaseProps) {
  const url = useAppUrls();
  return (
    <HStack
      h={12}
      minH={12}
      w="100vw"
      px={2}
      background={enurgenDark.toHexString()}
      zIndex={99}
      spacing={0}
      justify="space-between"
    >
      <HStack w="100%">
        <AppBarItem
          px={4}
          w="fit-content"
          minW="fit-content"
          to={url.projectsRoute()}
          _hover={undefined}
          isActive={() => false}
        >
          <DuetLogo w={14} />
        </AppBarItem>

        {children}
      </HStack>

      {rightElement}
    </HStack>
  );
}
