import "@fontsource/ibm-plex-mono/index.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/index.css";
import "@fontsource/jetbrains-mono/index.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { ReactNode, useMemo } from "react";
import "react-day-picker/dist/style.css";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { RootProviders } from "./AppProviders";
import { AppURLs } from "./appUrls";
import { InvitationRegisterPage } from "./features/auth/pages/InvitationRegisterPage";
import { LoginPage } from "./features/auth/pages/LoginPage";
import { PasswordResetPage } from "./features/auth/pages/PasswordResetPage";
import { RegisterPage } from "./features/auth/pages/RegisterPage";
import { NavigateHome } from "./features/home/NavigateHome";

export function App() {
  return (
    <RootProviders>
      <AppRouter />
    </RootProviders>
  );
}

function makeLazyRoute<T extends () => Promise<React.ComponentType>>(
  importer: T
) {
  return async function lazy() {
    const LazyComponent = await importer();

    function Component() {
      return <LazyComponent />;
    }

    return { Component };
  };
}

const lazyNotFoundPage = makeLazyRoute(() =>
  import("./components/NotFound/NotFound").then(
    (m) =>
      function NotFoundPage() {
        return <m.NotFound resource="page" />;
      }
  )
);

function AppRouter() {
  const url = useMemo(
    () =>
      new AppURLs({
        organizationSlug: ":organization",
      }),
    []
  );
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          lazy: () =>
            import("./AppProviders").then((m) => ({
              Component: m.AppProviders,
            })),
          children: [
            {
              lazy: () =>
                import("./features/auth/AuthRoute").then((m) => ({
                  Component: m.AuthRoute,
                })),
              children: [
                {
                  path: url.indexRoute().toString(),
                  element: <NavigateHome />,
                },
                {
                  path: url.projectsRoute().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/home/OrganizationProjectsPage").then(
                      (m) => m.OrganizationProjectsPage
                    )
                  ),
                },
                {
                  path: url.homeRoute().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/home/HomePage").then((m) => m.HomePage)
                  ),
                },
                {
                  path: url.adminFlags().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/admin/AdminFlags").then(
                      (m) => m.AdminFlags
                    )
                  ),
                },
                {
                  path: url.adminInviteUser().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/admin/AdminInviteUser").then(
                      (m) => m.AdminInviteUser
                    )
                  ),
                },
                {
                  path: url.adminInverterDataset().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/admin/AdminInverterDataset").then(
                      (m) => m.AdminInverterDataset
                    )
                  ),
                },
                {
                  path: url.adminImpersonateUser().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/admin/AdminImpersonateUser").then(
                      (m) => m.AdminImpersonateUser
                    )
                  ),
                },
                {
                  path: url.adminUsers().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/admin/AdminUsers").then(
                      (m) => m.AdminUsersPage
                    )
                  ),
                },
                {
                  path: url.projectOpenRoute().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/home/NavigateHome").then(
                      (m) => m.NavigateHome
                    )
                  ),
                },
                {
                  path: url.projectRoute(":projectId").toString(),
                  lazy: () =>
                    import(
                      "./features/projectsCommon/components/ProjectProvider"
                    ).then((m) => ({
                      Component: withOutlet(m.ProjectProvider),
                    })),
                  children: [
                    {
                      path: url
                        .projectDesignBlockRoute(":projectId")
                        .toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/projectDesign/pages/ProjectDesignBlockPage/ProjectDesignBlockPage"
                        ).then((m) => m.ProjectDesignBlockPage)
                      ),
                    },
                    {
                      path: url
                        .projectDesignComponentRoute(
                          ":projectId",
                          ":componentType?",
                          ":componentId?"
                        )
                        .toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/projectDesign/pages/ProjectDesignComponentPage/ProjectDesignComponentPage"
                        ).then((m) => m.ProjectDesignComponentPage)
                      ),
                    },
                    {
                      path: url.projectSimBlocksRoute(":projectId").toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/projectDesign/pages/ProjectSimBlockPage/ProjectSimBlockPage"
                        ).then((m) => m.ProjectSimBlockPage)
                      ),
                    },
                    {
                      path: url
                        .projectSimMeteoRoute(
                          ":projectId",
                          ":enviroDatasetOwnerId"
                        )
                        .toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/projectDesign/pages/ProjectSimMeteoPage/ProjectSimMeteoPage"
                        ).then((m) => m.ProjectSimMeteoPage)
                      ),
                    },
                    {
                      path: url.projectMeteoIndexRoute(":projectId").toString(),
                      lazy: makeLazyRoute(() =>
                        import("./features/meteo/routes/MeteoIndexPage").then(
                          (m) => m.MeteoIndexPage
                        )
                      ),
                    },
                    {
                      path: url
                        .projectSimulationsIndexRoute(":projectId")
                        .toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/projectSimResults/ProjectSimIndexPage"
                        ).then((m) => m.ProjectSimIndexPage)
                      ),
                    },
                    {
                      path: url
                        .projectSimulationRoute(":projectId", ":simulationId")
                        .toString(),
                      lazy: () =>
                        import(
                          "./features/projectSimDashboards/SimDashboardContextProvider"
                        ).then((m) => ({
                          Component: m.SimDashboardContextProvider,
                        })),
                      children: [
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "energy-yields"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/ProjectSimEnergyYieldDashboard"
                            ).then((m) => m.ProjectSimEnergyYieldDashboard)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "dc-energy-yields"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/monthlyEnergyYields/MonthlyDCEnergyPage"
                            ).then((m) => m.MonthlyDCEnergyYields)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "ac-energy-yields"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/monthlyEnergyYields/MonthlyACEnergyPage"
                            ).then((m) => m.MonthlyACEnergy)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "panel-temperature"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/monthlyEnergyYields/MonthlyCellTemperaturePage"
                            ).then((m) => m.MonthlyCellTemperaturePage)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "energy-losses"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/lossDiagram/ProjectSimLosses"
                            ).then((m) => m.ProjectSimDashboardLosses)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "drill-down"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/timeSeriesAnalysis/TimeSeriesAnalysisPage"
                            ).then((m) => m.ProjectSimTimeSeriesAnalysisPage)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "irradiance"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/ProjectIrradianceDashboard"
                            ).then((m) => m.ProjectIrradianceDashboard)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "data-export"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/simExport/ProjectSimDataExportPage"
                            ).then((m) => m.ProjectSimDataExportPage)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "pdf-report"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/PDFReportPage"
                            ).then((m) => m.PDFReportPage)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "module-irradiance"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/moduleIrradianceProfiles/ModuleIrradianceProfilesPage"
                            ).then((m) => m.ModuleIrradianceProfilesPage)
                          ),
                        },
                        {
                          path: url
                            .projectSimulationsDetailRoute(
                              ":projectId",
                              ":simulationId",
                              "pvsyst-factors"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/projectSimDashboards/ProjectSimPvsystFactors"
                            ).then((m) => m.ProjectSimPvsystFactors)
                          ),
                        },
                      ],
                    },
                    {
                      path: url
                        .projectCompareIndexRoute(":projectId")
                        .toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/projectCompare/ProjectCompareIndexPage"
                        ).then((m) => m.ProjectCompareIndexPage)
                      ),
                    },
                    {
                      path: url
                        .projectCompareWorkbookRoute(
                          ":projectId",
                          ":workbookId"
                        )
                        .toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/projectCompare/CompareAppEmbedded"
                        ).then((m) => m.CompareAppEmbedded)
                      ),
                    },
                    {
                      path: url.projectPlantPlayback(":projectId").toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/plantPlayback/PlantPlaybackPage"
                        ).then((m) => m.PlantPlaybackPage)
                      ),
                    },
                    {
                      path: url
                        .projectPlantPlayback(":projectId", ":id")
                        .toString(),
                      lazy: makeLazyRoute(() =>
                        import(
                          "./features/plantPlayback/PlantPlaybackPage"
                        ).then((m) => m.PlantPlaybackPage)
                      ),
                    },

                    // Monitor
                    {
                      lazy: () =>
                        import(
                          "./features/monitor/components/SentinelDeploymentProvider/SentinelDeploymentProvider"
                        ).then((m) => ({
                          Component: withOutlet(m.SentinelDeploymentProvider),
                        })),
                      children: [
                        {
                          path: url
                            .projectMonitorIndexRoute(":projectId")
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorOverviewPage/MonitorOverviewPage"
                            ).then((m) => m.MonitorOverviewPage)
                          ),
                        },
                        {
                          path: url
                            .projectMonitorIssuesIndexRoute(
                              ":projectId",
                              ":status"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorIssuesIndexPage/MonitorIssuesIndexPage"
                            ).then((m) => m.MonitorIssuesIndexPage)
                          ),
                        },

                        {
                          path: url
                            .projectMonitorMeteoRoute(":projectId")
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorMeteoPage/MonitorMeteoPage"
                            ).then((m) => m.MonitorMeteoPage)
                          ),
                        },
                        {
                          path: url
                            .projectMonitorModelPerformanceRoute(":projectId")
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorModelPerformancePage/MonitorModelPerformancePage"
                            ).then((m) => m.MonitorModelPerformancePage)
                          ),
                        },
                        {
                          path: url
                            .projectMonitorSystemLossesRoute(":projectId")
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorSystemLossPage/MonitorSystemLossesPage"
                            ).then((m) => m.MonitorSystemLoss)
                          ),
                        },
                        {
                          path: url
                            .projectMonitorBlockRoute(":projectId", ":blockId")
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorBlockRedirect"
                            ).then((m) => m.MonitorBlockRedirect)
                          ),
                        },
                        {
                          path: url
                            .projectMonitorBlockRoute(
                              ":projectId",
                              ":blockId",
                              "inverter"
                            )
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorBlockInverterPage/MonitorBlockInverterPage"
                            ).then((m) => m.MonitorBlockInverterPage)
                          ),
                        },
                        {
                          path: url
                            .projectMonitorSystemPerformanceRoute(":projectId")
                            .toString(),
                          lazy: makeLazyRoute(() =>
                            import(
                              "./features/monitor/routes/MonitorSystemPerformancePage/MonitorSystemPerformancePage"
                            ).then((m) => m.MonitorSystemPerformancePage)
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: url.settingsIndexPage().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/settings/SettingsIndexPage").then(
                      (m) => m.SettingsIndexPage
                    )
                  ),
                },
                {
                  path: url.settingsUserApiTokens().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/userApiTokens/UserApiTokensPage").then(
                      (m) => m.UserApiTokensPage
                    )
                  ),
                },
                {
                  path: url.settingsUserAccountEdit().toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/user/UserAccountEditPage").then(
                      (m) => m.UserAccountEditPage
                    )
                  ),
                },
                {
                  path: url.pv3Playground("panel").toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/pv3Playground/Pv3Panel").then(
                      (m) => m.Pv3Panel
                    )
                  ),
                },
                {
                  path: url.pv3Playground("array").toString(),
                  lazy: makeLazyRoute(() =>
                    import("./features/pv3Playground/Pv3Array").then(
                      (m) => m.Pv3Array
                    )
                  ),
                },
                {
                  path: url.onboardingIntakeForm().toString(),
                  lazy: makeLazyRoute(() =>
                    import(
                      "./features/onboarding/UserOnboardingIntakePage"
                    ).then((m) => m.UserOnboardingIntakePage)
                  ),
                },
                {
                  path: url.emailVerification().toString(),
                  lazy: makeLazyRoute(() =>
                    import(
                      "./features/auth/pages/EmailVerificationIntercept"
                    ).then((m) => m.EmailVerificationIntercept)
                  ),
                },
                {
                  path: url.organizationPicker().toString(),
                  lazy: makeLazyRoute(() =>
                    import(
                      "./features/organization/pages/organizationPicker/OrganizationPickerPage"
                    ).then((m) => m.OrganizationPickerPage)
                  ),
                },
                {
                  path: url.settingsOrganizationEdit().toString(),
                  lazy: makeLazyRoute(() =>
                    import(
                      "./features/organization/pages/organizationSettings/OrganizationSettingsPage"
                    ).then((m) => m.OrganizationSettingsPage)
                  ),
                },
                {
                  path: url.settingsOrganizationProjects().toString(),
                  lazy: makeLazyRoute(() =>
                    import(
                      "./features/organization/pages/organizationSettings/OrganizationProjectsPage"
                    ).then((m) => m.OrganizationProjectsPage)
                  ),
                },
                {
                  path: "*",
                  lazy: lazyNotFoundPage,
                },
              ],
            },
            {
              path: url.loginRoute().toString(),
              element: <LoginPage />,
            },
            {
              path: url.registerRoute().toString(),
              element: <RegisterPage />,
            },
            {
              path: url.passwordReset().toString(),
              element: <PasswordResetPage />,
            },
            {
              path: url.invitationRegister(":token").toString(),
              element: <InvitationRegisterPage />,
            },
          ],
        },
        {
          path: "*",
          lazy: lazyNotFoundPage,
        },

        // dev only routes

        ...[
          {
            path: "/_email",
            lazy: makeLazyRoute(() =>
              import("./features/emailLocalDev/EmailPreviewRoute").then(
                (m) => m.EmailPreviewRoute
              )
            ),
          },
        ].filter(Boolean),
      ]),
    []
  );

  return <RouterProvider router={router} />;
}

function withOutlet<P extends { children: ReactNode }>(
  Component: React.ComponentType<P>
) {
  function ComponentWithOutlet(props: Omit<P, "children">) {
    return (
      // @ts-expect-error
      <Component {...props}>
        <Outlet />
      </Component>
    );
  }
  ComponentWithOutlet.displayName = `withOutlet(${Component.displayName || Component.name})`;
  return ComponentWithOutlet;
}
