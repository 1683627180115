// this is a slight modification of the original code from turfjs to better account for our use case
// https://github.com/Turfjs/turf/blob/master/packages/turf-rectangle-grid/index.ts
// this commit is a bit suspect
// https://github.com/Turfjs/turf/commit/446b27be9a0b3ad44a1ae56d427173e3d83454bd
// we are using the original code from the commit before that one
import { Anchors } from "@duet/shared/geo";
import distance from "@turf/distance";
import { Units, polygon } from "@turf/helpers";
import intersect from "@turf/intersect";
import { booleanWithin } from "@turf/turf";
import {
  BBox,
  Feature,
  GeoJsonProperties,
  MultiPolygon,
  Polygon,
} from "geojson";
import { cloneDeep, get, round, set } from "lodash";

export type OriginAnchor = keyof Anchors | "center";

interface RectangleGridPolyProperties {
  row: number;
  column: number;
}

interface RectangleGridProperties {
  maxRows: number;
  maxColumns: number;
  origin?: OriginAnchor;
  maskStrategy?: "within" | "intersect";
}

interface RectangleGridFeatureCollection<
  P extends RectangleGridPolyProperties,
> {
  type: "FeatureCollection";
  features: Array<Feature<Polygon, P>>;
  properties: RectangleGridProperties;
}

interface RectangleGridOptions<P extends GeoJsonProperties> {
  units?: Units;
  properties?: P;
  mask?: Feature<Polygon | MultiPolygon> | Polygon | MultiPolygon;
  maskStrategy?: "within" | "intersect";
  origin?: OriginAnchor;
}

/**
 * Creates a grid of rectangles from a bounding box, {@link Feature} or {@link FeatureCollection}.
 *
 * @name rectangleGrid
 * @param {Array<number>} bbox extent in [minX, minY, maxX, maxY] order
 * @param {number} cellWidth of each cell, in units
 * @param {number} cellHeight of each cell, in units
 * @param {Object} [options={}] Optional parameters
 * @param {string} [options.units='kilometers'] units ("degrees", "radians", "miles", "kilometers") that the given cellWidth
 * and cellHeight are expressed in. Converted at the southern border.
 * @param {Feature<Polygon|MultiPolygon>} [options.mask] if passed a Polygon or MultiPolygon,
 * the grid Points will be created only inside it
 * @param {Object} [options.properties={}] passed to each point of the grid
 * @returns {FeatureCollection<Polygon>} a grid of polygons
 * @example
 * var bbox = [-95, 30 ,-85, 40];
 * var cellWidth = 50;
 * var cellHeight = 20;
 * var options = {units: 'miles'};
 *
 * var rectangleGrid = turf.rectangleGrid(bbox, cellWidth, cellHeight, options);
 *
 * //addToMap
 * var addToMap = [rectangleGrid]
 */
export function rectangleGrid<P extends GeoJsonProperties>(
  bbox: BBox,
  cellWidth: number,
  cellHeight: number,
  options: RectangleGridOptions<P> = {}
) {
  const maskStrategy = options.maskStrategy ?? "intersect";
  // Containers
  const results = [];
  const west = bbox[0];
  const south = bbox[1];
  const east = bbox[2];
  const north = bbox[3];

  const bboxWidth = east - west;
  const bboxHeight = north - south;
  const xFraction = cellWidth / distance([west, south], [east, south], options);
  const cellWidthDeg = xFraction * (east - west);
  const yFraction =
    cellHeight / distance([west, south], [west, north], options);
  const cellHeightDeg = yFraction * (north - south);

  const columns = Math.floor(bboxWidth / cellWidthDeg);
  const rows = Math.floor(bboxHeight / cellHeightDeg);

  const origin = options?.origin ?? "ne";

  const { deltaX, deltaY } = originDelta(origin, {
    bboxHeight,
    bboxWidth,
    columns,
    rows,
    cellHeightDeg,
    cellWidthDeg,
  });

  const featureCollectionProperties: RectangleGridProperties = {
    maxColumns: options.mask ? 0 : columns,
    maxRows: options.mask ? 0 : rows,
    origin,
    maskStrategy,
  };

  // iterate over columns & rows
  let currentX = west + deltaX;
  for (let column = 0; column < columns; column++) {
    let currentY = south + deltaY;
    for (let row = 0; row < rows; row++) {
      const cellPoly = polygon(
        [
          [
            [currentX, currentY],
            [currentX, currentY + cellHeightDeg],
            [currentX + cellWidthDeg, currentY + cellHeightDeg],
            [currentX + cellWidthDeg, currentY],
            [currentX, currentY],
          ],
        ],
        {
          ...options.properties,
          row,
          column,
        }
      );
      if (options.mask) {
        if (
          maskStrategy === "intersect" &&
          intersect(
            fixGeoJsonCoords(options.mask as Feature<Polygon>),
            fixGeoJsonCoords(cellPoly)
          )
        ) {
          featureCollectionProperties.maxColumns = Math.max(
            featureCollectionProperties.maxColumns,
            column + 1
          );
          featureCollectionProperties.maxRows = Math.max(
            featureCollectionProperties.maxRows,
            row + 1
          );
          results.push(cellPoly);
        }

        if (
          maskStrategy === "within" &&
          booleanWithin(cellPoly, options.mask)
        ) {
          featureCollectionProperties.maxColumns = Math.max(
            featureCollectionProperties.maxColumns,
            column + 1
          );
          featureCollectionProperties.maxRows = Math.max(
            featureCollectionProperties.maxRows,
            row + 1
          );
          results.push(cellPoly);
        }
      } else {
        results.push(cellPoly);
      }

      currentY += cellHeightDeg;
    }
    currentX += cellWidthDeg;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    type: "FeatureCollection",
    features: results,
    properties: featureCollectionProperties,
  } as RectangleGridFeatureCollection<P & RectangleGridPolyProperties>;
}

function originDelta(
  origin: OriginAnchor,
  dims: {
    bboxWidth: number;
    bboxHeight: number;
    columns: number;
    rows: number;
    cellWidthDeg: number;
    cellHeightDeg: number;
  }
) {
  const { bboxHeight, bboxWidth, columns, rows, cellHeightDeg, cellWidthDeg } =
    dims;

  // if the grid does not fill the bbox perfectly, center it.
  let deltaX = (bboxWidth - columns * cellWidthDeg) / 2;
  let deltaY = (bboxHeight - rows * cellHeightDeg) / 2;

  // y delta conditions
  if (["nw", "n", "ne"].includes(origin)) {
    deltaY = bboxHeight - rows * cellHeightDeg;
  }

  if (["sw", "s", "se"].includes(origin)) {
    deltaY = 0;
  }

  // x delta conditions
  if (["sw", "nw", "w"].includes(origin)) {
    deltaX = 0;
  }

  if (["se", "ne", "e"].includes(origin)) {
    deltaX = 0;
  }

  return {
    deltaX,
    deltaY,
  };
}

// https://github.com/Turfjs/turf/issues/2048
function fixGeoJsonCoords(geojson: Feature<Polygon>): Feature<Polygon> {
  const fixedCoords = get(geojson, "geometry.coordinates.0")?.map((coord) => {
    return coord.map((c) => round(c, 8));
  });
  if (!fixedCoords) return geojson;
  return set(cloneDeep(geojson), "geometry.coordinates.0", fixedCoords);
}
