import {
  along,
  distance,
  Feature,
  length,
  lineSlice,
  lineString,
  LineString,
  Position,
} from "@turf/turf";
import { isNil, range } from "lodash";
import { HeightLineProperties, ShadingLayer } from "../models/BlockLayerModel";
import { coordsToVector3 } from "../utils/coordsToVector";

export const DEFAULT_SAMPLING_DISTANCE = 10;
export const DEFAULT_SHADING_HEIGHT = 10;
const ELEVATION_CHANGE_THRESHOLD = 0.25;

export function addDefaultPointHeights(
  layer: ShadingLayer<LineString, HeightLineProperties>
) {
  const points = layer.geojson.geometry.coordinates;
  const pointHeights = points.reduce<HeightLineProperties["pointHeights"]>(
    (acc, _, i) => ({
      ...acc,
      [i]: DEFAULT_SHADING_HEIGHT,
    }),
    {}
  );

  layer.geojson.properties.pointHeights = pointHeights;
}

export function generateShadingObjectVertices(
  points: [number, number, number][],
  pointHeights: Record<number, number>
): Float32Array {
  const values = points.reduce<number[]>((acc, curr, i, arr) => {
    if (i === arr.length - 1) {
      return acc;
    }

    const next = arr[i + 1];

    const currHeight = pointHeights[i] ?? DEFAULT_SHADING_HEIGHT;
    const nextHeight = pointHeights[i + 1] ?? DEFAULT_SHADING_HEIGHT;
    const currBot = [curr[0], curr[1], curr[2]];
    const nextBot = [next[0], next[1], next[2]];
    const currTop = [curr[0], curr[1] + currHeight, curr[2]];
    const nextTop = [next[0], next[1] + nextHeight, next[2]];

    acc.push(...nextTop, ...currTop, ...currBot);
    acc.push(...currBot, ...nextBot, ...nextTop);
    return acc;
  }, []);

  return new Float32Array(values);
}

/**
 * Generate a line string with smaller segments for more elevation samples
 *
 * Will generate an updated pointHeight property.
 *
 * @param feature
 * @param maxDist {number} maximum distance in meters between segments
 */
export function resampleHeightLine(
  feature: Feature<LineString, HeightLineProperties>,
  getElevation: (lng: number, lat: number) => number,
  maxDist: number = DEFAULT_SAMPLING_DISTANCE
): Feature<LineString, HeightLineProperties> {
  const originalPointHeights = feature.properties.pointHeights ?? {};
  const originalCoordinates = feature.geometry.coordinates.map(
    ([longitude, latitude]) => [
      longitude,
      latitude,
      getElevation(longitude, latitude),
    ]
  );

  const coordinates = [originalCoordinates[0]];
  const pointHeights: HeightLineProperties["pointHeights"] = {};

  if (originalPointHeights[0] !== undefined) {
    pointHeights[0] = originalPointHeights[0];
  }

  for (let i = 1; i < originalCoordinates.length; i++) {
    const from = originalCoordinates[i - 1];
    const to = originalCoordinates[i];

    coordinates.push(...resampleSegment(from, to, getElevation, maxDist), to);

    // We'll be interpolating line height later,
    // for now we only need to make sure the coordinate indices match
    if (originalPointHeights[i] !== undefined) {
      pointHeights[coordinates.length - 1] = originalPointHeights[i];
    }
  }

  return lineString(
    coordinates,
    {
      ...feature.properties,
      pointHeights,
    },
    { id: feature.id }
  );
}

function resampleSegment(
  from: Position,
  to: Position,
  getElevation: (lon: number, lat: number) => number,
  maxDist: number
): Position[] {
  const dist = distance(from, to, { units: "meters" });

  if (dist <= maxDist) {
    return [];
  }

  const segment = lineString([from, to]);
  const samples = Math.floor(dist / maxDist);
  const points = [
    from,
    ...range(1, samples).map((n) => {
      const t = n / (samples + 1);
      const [lon, lat] = along(segment, t * dist, { units: "meters" }).geometry
        .coordinates;

      return [lon, lat, getElevation(lon, lat)];
    }),
    to,
  ];

  let deleted;
  do {
    deleted = 0;

    for (let i = points.length - 2; i > 0; i--) {
      const prev = points[i - 1][2];
      const curr = points[i][2];
      const next = points[i + 1][2];

      const mid = (prev + next) / 2;
      const diff = Math.abs(curr - mid);

      if (diff < ELEVATION_CHANGE_THRESHOLD) {
        deleted++;
        points.splice(i, 1);
      }
    }
  } while (deleted > 0 && points.length > 2);

  return points.slice(1, -1);
}

export function getCartesianPoints(
  feature: Feature<LineString>,
  origin: { longitude: number; latitude: number }
): [number, number, number][] {
  const { coordinates } = feature.geometry;
  return coordinates.map(([longitude, latitude, altitude = 0]) =>
    coordsToVector3(
      {
        longitude,
        latitude,
        altitude,
      },
      origin
    )
  );
}

export function getPointHeights(
  feature: Feature<LineString, HeightLineProperties>
): Record<number, number> {
  const heights: Record<number, number> = {};
  for (let i = 0; i < feature.geometry.coordinates.length; i++) {
    heights[i] = getInterpolatedHeight(feature, i) ?? DEFAULT_SHADING_HEIGHT;
  }

  return heights;
}

export function getInterpolatedHeight(
  feature: Feature<LineString, HeightLineProperties>,
  coordIndex: number
) {
  const coordinates = feature.geometry.coordinates;
  const pointHeights = feature.properties?.pointHeights ?? {};

  if (!isNil(pointHeights[coordIndex])) {
    return pointHeights[coordIndex];
  }

  let prevDefinedPoint = -1;
  let nextDefinedPoint = -1;

  for (let i = coordIndex - 1; i > -1; i--) {
    if (!isNil(pointHeights?.[i])) {
      prevDefinedPoint = i;
      break;
    }
  }
  for (let i = coordIndex + 1; i < coordinates.length; i++) {
    if (!isNil(pointHeights?.[i])) {
      nextDefinedPoint = i;
      break;
    }
  }

  if (prevDefinedPoint > -1 && nextDefinedPoint > -1) {
    const prev = coordinates[prevDefinedPoint];
    const next = coordinates[nextDefinedPoint];
    const prevHeight = pointHeights[prevDefinedPoint];
    const nextHeight = pointHeights[nextDefinedPoint];

    const prevToNext = lineSlice(prev, next, feature);
    const prevToCurr = lineSlice(prev, coordinates[coordIndex], feature);
    const t = length(prevToCurr) / length(prevToNext);

    return prevHeight + (nextHeight - prevHeight) * t;
  }

  return pointHeights[prevDefinedPoint] ?? pointHeights[nextDefinedPoint];
}
