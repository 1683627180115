import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    border: "1px solid",
    borderColor: "gray.300",
    // Let's also provide dark mode alternatives
    _focus: {
      borderColor: "gray.600",
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    // for some reason we need to override the default variant for baseStyle to work
    variant: "outline",
  },
});
