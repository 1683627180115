import { useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useAppUrls } from "~/appUrls";
import { NoAccess } from "~/components/NoAccess/NoAccess";
import { useSessionStorageState } from "~/hooks/useStorageState";
import { useAppDispatch } from "~/redux/store";
import { setOrganization } from "./organizationSlice";
import { useUserOrganizations } from "./useOrganization";

export const ORG_SESSION_STORAGE_KEY = "organization";

export function clearOrganizationSessionState() {
  sessionStorage.setItem(ORG_SESSION_STORAGE_KEY, "null");
}

export function OrganizationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const urls = useAppUrls();
  const location = useLocation();
  const { organization: orgRouteParam, projectId } = useParams();

  const { userOrganizations } = useUserOrganizations();

  const orgSlugs = userOrganizations.map((org) => org.slug);
  const hasOrgs = orgSlugs.length > 0;
  const isUserInOrg = orgSlugs.includes(orgRouteParam ?? "");

  const [selectedOrganizationSlug, setSelectedOrganizationSlug] =
    useSessionStorageState<string | null>(ORG_SESSION_STORAGE_KEY, null);

  if (
    orgRouteParam &&
    orgRouteParam !== selectedOrganizationSlug &&
    isUserInOrg
  ) {
    setSelectedOrganizationSlug(orgRouteParam);
  }

  const selectedOrg = userOrganizations.find(
    (uo) => uo.slug === selectedOrganizationSlug
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setOrganization(selectedOrg ?? null));
  }, [selectedOrg]);

  const defaultRender = <>{children}</>;

  if (!hasOrgs) {
    const to = urls.organizationPicker();

    // this is fucking terrible
    if (
      location.pathname !== to.pathname &&
      !location.pathname.includes("/auth") &&
      !location.pathname.includes("/onboarding")
    ) {
      return <Navigate to={to} />;
    }
    return defaultRender;
  }

  if (!orgRouteParam) {
    return defaultRender;
  }

  if (!selectedOrganizationSlug && !projectId) {
    const targetOrg = selectedOrganizationSlug || orgSlugs[0];
    const to = urls.setOrganizationSlug(targetOrg).projectsRoute();

    return <Navigate to={to} />;
  }

  // TODO: quick hack to allow access to projects that a user belongs to
  if (!isUserInOrg && !projectId) {
    return <NoAccess resource="organization" resourceName={orgRouteParam} />;
  }

  return defaultRender;
}
