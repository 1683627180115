interface TaskPublicAPI {
  id: string;
  type: string;
  name: string;
  link?: string;
  retries: number;
  delay: number;
  promise: () => Promise<any>;
}

interface Task extends TaskPublicAPI {
  attempts: number;
  status: "pending" | "running" | "retrying" | "resolved" | "rejected";
  error: unknown;
  data: unknown;
}

export type BackgroundTask = Task;

interface EventMap {
  taskAdded: (task: Task) => void;
  taskRemoved: (task: Task) => void;
  taskStateChanged: (task: Task) => void;
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export class BackgroundTaskManager {
  private readonly tasksById = new Map<string, Task>();
  private readonly listeners = new Map<keyof EventMap, Set<(a: any) => any>>();

  get tasks() {
    return this.tasksById.values();
  }

  addTask(task: TaskPublicAPI) {
    if (this.tasksById.has(task.id)) {
      throw new Error(`Task with id ${task.id} already exists`);
    }

    const _task: Task = {
      ...task,
      attempts: 0,
      status: "pending",
      error: undefined,
      data: undefined,
    };

    this.tasksById.set(task.id, _task);
    this.runTask(_task);
    this.emit("taskAdded", _task);
  }

  removeTask(id: string) {
    const task = this.tasksById.get(id);
    if (!task) {
      throw new Error(`Task with id ${id} does not exist`);
    }
    this.tasksById.delete(id);
    this.emit("taskRemoved", task);
  }

  on<E extends keyof EventMap>(event: E, listener: EventMap[E]) {
    if (!this.listeners.has(event)) {
      this.listeners.set(event, new Set());
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.listeners.get(event)!.add(listener);

    return this.off.bind(this, event, listener);
  }

  off<E extends keyof EventMap>(event: E, listener: EventMap[E]) {
    this.listeners.get(event)?.delete(listener);
  }

  private emit<E extends keyof EventMap>(
    event: E,
    arg: Parameters<EventMap[E]>[0]
  ) {
    this.listeners.get(event)?.forEach((listener) => listener(arg));
  }

  private updateTask(task: Task, update: Partial<Task>) {
    Object.assign(task, update);
    this.emit("taskStateChanged", task);
  }

  private async runTask(task: Task) {
    try {
      this.updateTask(task, {
        status: "running",
        attempts: ++task.attempts,
      });

      const data = await task.promise();

      this.updateTask(task, {
        data,
        status: "resolved",
      });
    } catch (error) {
      if (task.retries > 0 && task.attempts < task.retries) {
        console.warn(
          `Task failed, retrying... (${task.retries - task.attempts} retries left)`,
          error
        );

        this.updateTask(task, {
          status: "retrying",
        });

        await wait(task.delay);
        await this.runTask(task);
      } else {
        this.updateTask(task, {
          status: "rejected",
          error,
        });
        this.emit("taskStateChanged", task);
        console.error("Task failed after retries:", error);
      }
    }
  }
}
