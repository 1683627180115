import { isPolygon, solarAzimuthToBearing } from "@duet/shared/geo";
import {
  Point,
  along,
  area,
  bbox,
  bearing,
  bearingToAzimuth,
  booleanContains,
  booleanOverlap,
  booleanPointInPolygon,
  center,
  destination,
  distance,
  envelope,
  featureCollection,
  getCoord,
  intersect,
  length,
  lineSplit,
  lineString,
  nearestPointOnLine,
  point,
  polygon,
} from "@turf/turf";
import { Feature, LineString, Polygon, Position } from "geojson";

/**
 * calcRowLines - calculate pv array row lines to be used as a reference for placing pv modules
 * row lines are orthogonal to the guide line and the azimuth of the array
 * @param poly - pv array generation polygon
 * @param spacing - distance between each row line, typically the table bounding box height
 * @param bearing - azimuth of the array, represented as a map bearing in degrees
 * @param origin - origin point for the row lines, default is the bottom left corner of the bounding box
 * @param dir - direction of the row lines, 1 for forward, -1 for backward
 */

function calcRowLines(
  poly: Feature<Polygon>,
  spacing: number,
  bearing: number,
  origin: Position
) {
  if (poly.geometry.coordinates.length === 0) {
    return featureCollection<LineString, any>([]);
  }

  const [minX, minY] = bbox(poly);

  const lines = [];

  // Get the diagonal length of the bounding box to ensure full coverage
  const diagonalDistance = calcDiagonalDistance(poly);

  origin = origin ?? [minX, minY];

  // Generate lines along the bounding box width
  for (let d = 0; d <= diagonalDistance; d += spacing) {
    if (d === 0) {
      lines.push(...calcRowLine(origin, d, bearing, diagonalDistance, poly));
      continue;
    }
    lines.push(
      ...calcRowLine(origin, d, bearing, diagonalDistance, poly),
      ...calcRowLine(origin, d * -1, bearing, diagonalDistance, poly)
    );
  }

  return featureCollection<LineString, any>(lines);
}

function calcRowLine(
  origin: Position,
  originOffset: number,
  bearing: number,
  lineSegmentLength: number,
  mask: Feature<Polygon>
) {
  const startPoint = destination(origin, originOffset, bearing, {
    units: "meters",
  });

  // Calculate the forward endpoint for the line
  const forwardEndPoint = destination(
    startPoint.geometry.coordinates,
    lineSegmentLength,
    bearing + 90,
    { units: "meters" }
  );

  // Calculate the backward endpoint for the line
  const backwardEndPoint = destination(
    startPoint.geometry.coordinates,
    lineSegmentLength,
    bearing - 90,
    { units: "meters" }
  );

  // Create the line extending in both directions
  const line = lineString([
    backwardEndPoint.geometry.coordinates,
    forwardEndPoint.geometry.coordinates,
  ]);

  const rowLinesSplit = lineSplit(line, mask);
  const lines: Array<Feature<LineString>> = [];

  for (const rowLineSplit of rowLinesSplit.features) {
    const mid = center(line);
    const isInPoly = booleanPointInPolygon(mid, mask);
    if (isInPoly) {
      lines.push(rowLineSplit);
    }
  }

  return lines;
}

/**
 * calcRectAlongLine - calculate a rectangular polygon along a line
 * this function receives a row line and will place a rectangular polygon along the line
 * the dimensions of the rectangle should represented the bounding box of a pv table including spacing
 * the pv table will be placed in the center of this rectangle
 * @param line - row line to place the rectangle along
 * @param width - width of the rectangle
 * @param height - height of the rectangle
 */
function calcRectsAlongLine(
  line: Feature<LineString>,
  width: number,
  height: number
) {
  const rects = [];
  const lineLength = length(line, { units: "meters" });
  const [l0, l1] = line.geometry.coordinates;
  const lineBearing = bearing(l0, l1);
  const totalRects = Math.floor(lineLength / width);

  for (let i = 0; i < totalRects; i++) {
    const origin = along(line, i * width, { units: "meters" });
    rects.push(calcRect(origin, lineBearing, width, height));
  }

  return featureCollection<Polygon, any>(rects);
}

export function calcRect(
  origin: Feature<Point>,
  bearing: number,
  width: number,
  height: number
) {
  const halfHeight = height / 2;
  const p0 = destination(origin, halfHeight, bearing + 90, {
    units: "meters",
  });
  const p1 = destination(origin, halfHeight, bearing - 90, {
    units: "meters",
  });
  const p2 = destination(p1, width, bearing, { units: "meters" });
  const p3 = destination(p0, width, bearing, { units: "meters" });
  return polygon([
    [
      p0.geometry.coordinates,
      p1.geometry.coordinates,
      p2.geometry.coordinates,
      p3.geometry.coordinates,
      p0.geometry.coordinates,
    ],
  ]);
}

/**
 * extendLineSegment - extend a line segment by a given distance
 * @param line - line segment to extend
 * @param extensionDistance - distance to extend the line segment by
 */
function extendLineSegment(
  line: Feature<LineString>,
  extensionDistance: number
) {
  const [p0, p1] = line.geometry.coordinates;

  const lineBearing = bearing(p0, p1);

  const nextP1 = destination(p0, extensionDistance, lineBearing, {
    units: "meters",
  });

  const nextP0 = destination(p0, extensionDistance, flipBearing(lineBearing), {
    units: "meters",
  });

  return lineString([nextP0.geometry.coordinates, nextP1.geometry.coordinates]);
}

/**
 * calcDiagonalDistance - calculate the diagonal distance of a polygon
 * @param poly - polygon to calculate the diagonal distance for
 */
export function calcDiagonalDistance(poly: Feature<Polygon>) {
  const [minX, minY, maxX, maxY] = bbox(poly);
  return distance([minX, minY], [maxX, maxY], {
    units: "meters",
  });
}

/**
 * flioBearing - returns the opposite bearing of a given bearing
 * @param bearing
 */
export function flipBearing(bearing: number) {
  return bearing < 0 ? bearing + 180 : bearing - 180;
}

/**
 * extendLine - extend a line by a given distance, supports single segment and multi segment lines
 * multi segment lines will extend the first and last segments by the extension distance
 * the extensionDistance works in both directions, the overally line will be extended by 2 * extensionDistance
 * @param line - line to extend
 * @param extensionDistance - distance to extend the line by
 */
function extendLine(line: Feature<LineString>, extensionDistance: number) {
  if (line.geometry.coordinates.length < 2) {
    return line;
  }

  if (line.geometry.coordinates.length === 2) {
    const extendedLine = extendLineSegment(line, extensionDistance);
    return extendedLine;
  }

  const lineCoords = Array.from(line.geometry.coordinates);

  const firstSegmentCoords = line.geometry.coordinates.slice(0, 2).reverse();
  const lastSegmentCoords = line.geometry.coordinates.slice(-2);

  const firstSegment = lineString(firstSegmentCoords);
  const lastSegment = lineString(lastSegmentCoords);

  const nextP0 = extendLineSegment(firstSegment, extensionDistance).geometry
    .coordinates[1];
  const nextPn = extendLineSegment(lastSegment, extensionDistance).geometry
    .coordinates[1];

  lineCoords[0] = nextP0;
  lineCoords[lineCoords.length - 1] = nextPn;

  return lineString<any>(lineCoords);
}

export interface AlignmentOptions {
  // distance between each row line, typically the table bounding box height
  lineSpacing: number;
  // azimuth of the array
  azimuth: number;
  // width of a pv table bounding box
  rectWidth: number;
  // height of a pv table bounding box
  rectHeight: number;
  // offset the origin point by a given distance
  originOffset?: number;

  alignTo?: "line" | "polygon";
}

/**
 * calcArrayAlignment - generates the pv array layout for a given polygon and guide line
 * @param poly
 * @param guideLine
 * @param options
 * @returns
 */
export function calcArrayAlignment(
  poly: Feature<Polygon>,
  guideLine: Feature<LineString>,
  options: AlignmentOptions
) {
  const {
    lineSpacing,
    azimuth,
    rectWidth,
    rectHeight,
    originOffset = rectHeight * 0.5,
    alignTo = "line",
  } = options;

  const clampedLine = clampAtBackwardSegment(guideLine);
  const envelopeGeojson = envelope(featureCollection<any>([poly, clampedLine]));

  const diagonalDistance = calcDiagonalDistance(envelopeGeojson);
  const extendedGuideLine = extendLine(clampedLine, diagonalDistance);
  const extendedEnvelope = envelope(
    featureCollection<any>([poly, extendedGuideLine])
  );

  const origin = calcRowLinesOrigin(poly, clampedLine, originOffset);

  const envelopeRowLines = calcRowLines(
    extendedEnvelope as Feature<Polygon>,
    lineSpacing,
    solarAzimuthToBearing(azimuth),
    getCoord(origin)
  );

  const rowLines = featureCollection([]);

  const containedRects: Array<Feature<Polygon>> = [];
  for (const rowLine of envelopeRowLines.features) {
    const splitLines = lineSplit(rowLine, extendedGuideLine);

    for (const [idx, line] of splitLines.features.entries()) {
      if (idx === 0) {
        line.geometry.coordinates = line.geometry.coordinates.reverse();
      }

      if (alignTo === "line") {
        const rects = calcRectsAlongLineInPolygon(
          line,
          rectWidth,
          rectHeight,
          poly
        );
        if (rects.length === 0) continue;
        rowLines.features.push(line);
        containedRects.push(...rects);
      }

      if (alignTo === "polygon") {
        const rowLinesSplitByPoly = lineSplit(line, poly);

        for (const rowLineSplit of rowLinesSplitByPoly.features) {
          const rects = calcRectsAlongLineInPolygon(
            rowLineSplit,
            rectWidth,
            rectHeight,
            poly
          );

          if (rects.length === 0) continue;
          rowLines.features.push(rowLineSplit);
          containedRects.push(...rects);
        }
      }
    }
  }

  const numRows = rowLines.features.length;
  const numTables = containedRects.length;
  return {
    rowLinesOrigin: origin,
    rowLines,
    extendedGuideLine,
    rects: featureCollection<Polygon, any>(containedRects),
    numRows,
    numTables,
  };
}

function clampAtBackwardSegment(guideLine: Feature<LineString>) {
  if (guideLine.geometry.coordinates.length < 3) {
    return guideLine;
  }
  const firstSegment = guideLine.geometry.coordinates.slice(0, 2);

  const initialAzmuth = bearingToAzimuth(
    bearing(firstSegment[0], firstSegment[1])
  );
  let lastCoord = firstSegment[1];

  for (let i = 2; i < guideLine.geometry.coordinates.length; i++) {
    const coord = guideLine.geometry.coordinates[i];
    const currentAzimuth = bearingToAzimuth(bearing(lastCoord, coord));

    if (Math.abs(initialAzmuth - currentAzimuth) > 90) {
      return lineString([
        ...guideLine.geometry.coordinates.slice(0, i),
        lastCoord,
      ]);
    }
    lastCoord = coord;
  }

  return guideLine;
}

function calcRectsAlongLineInPolygon(
  line: Feature<LineString>,
  width: number,
  height: number,
  poly: Feature<Polygon>
) {
  const containedRects: Array<Feature<Polygon>> = [];
  const rects = calcRectsAlongLine(line, width, height);
  for (const rect of rects.features) {
    if (!polyContainsRect(poly, rect)) {
      continue;
    }

    containedRects.push(rect);
  }

  return containedRects;
}

function polyContainsRect(poly: Feature<Polygon>, rect: Feature<Polygon>) {
  const contained = booleanContains(poly, rect);

  if (contained) {
    return true;
  }

  const overlaps = booleanOverlap(poly, rect);
  if (overlaps) {
    const unioned = intersect(poly, rect);
    if (!isPolygon(unioned)) {
      return false;
    }

    const unionedArea = area(unioned);
    const rectArea = area(rect);

    const diff = rectArea - unionedArea;

    return diff < 5;
  }

  return false;
}

/**
 * calcRowLinesOrigin - calculate the origin point
 * @param poly - pv array polygon
 * @param guideLine - guide line
 * @param options
 */
function calcRowLinesOrigin(
  poly: Feature<Polygon>,
  guideLine: Feature<LineString>,
  offset = 0
) {
  const guideLineCoords = guideLine.geometry.coordinates;

  const polyLineString = polyToLineString(poly);

  let origin = point(guideLineCoords[0]);

  origin = nearestPointOnLine(polyLineString, origin);

  if (offset > 0) {
    const guideLineBearing = bearing(
      guideLineCoords[0],
      guideLineCoords[guideLineCoords.length - 1]
    );

    origin = destination(getCoord(origin), offset, guideLineBearing, {
      units: "meters",
    });
  }

  return origin;
}

function polyToLineString(poly: Feature<Polygon>) {
  return lineString(poly.geometry.coordinates[0]);
}
