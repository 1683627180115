import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useAuth } from "~/features/auth/AuthContext";
import { useAdminUserMeQuery } from "~/gql/generated";
import { useActiveUser } from "../features/user/hooks";
import { ampli } from "./amplitude";

export function TrackingAuthUser() {
  const { user } = useActiveUser();
  const { isImpersonating } = useAuth();

  const adminUserQuery = useAdminUserMeQuery({}, { skip: !isImpersonating });

  useEffect(() => {
    const userPayload: {
      email?: string;
      impersonator?: string;
    } = {};

    Object.assign(userPayload, {
      email: user.email,
    });

    if (adminUserQuery.data?.adminMe.email) {
      Object.assign(userPayload, {
        impersonator: adminUserQuery.data?.adminMe.email,
      });
    }
    const organization = user.email.split("@")[1];

    if (import.meta.env.DEV) return;

    Sentry.setUser(userPayload);
    ampli.identify(userPayload.email);
    ampli.client.setGroup("organization", organization);
  }, [user, adminUserQuery.data?.adminMe.email]);

  return null;
}
