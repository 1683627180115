import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Nullable } from "vitest";
import { NotFound } from "~/components/NotFound/NotFound";
import {
  useGetProjectQuery,
  type GetProjectEnviroDatasetsQuery,
  type GetProjectQuery,
} from "~/gql/generated";
import { useAppDispatch } from "~/redux/store";
import { BlocksBoundingBox, setBlocksBoundingBox } from "../projectSlice";

export type Project = GetProjectQuery["project"];
export type EnviroDataset =
  GetProjectEnviroDatasetsQuery["projectEnviroDatasets"][number]["enviro_dataset"];

// TODO: should just be its own context instead of react router context
export interface ProjectProviderContextValue {
  isLoading: boolean;
  project: Project | null | undefined;
  projectId: string;
  refetchProject: () => Promise<any>;
}

const ProjectProviderContext =
  createContext<ProjectProviderContextValue | null>(null);

export function useProjectContext() {
  const ctx = useContext(ProjectProviderContext);
  if (!ctx) {
    throw new Error("useProjectContext must be used within a ProjectProvider");
  }
  return ctx;
}

export const ProjectProvider = ({ children }: { children?: ReactNode }) => {
  const { projectId } = useParams<{ projectId: string }>();

  if (!projectId) {
    return null;
  }

  const [project, setProject] = useState<Project>();
  const dispatch = useAppDispatch();

  const {
    data,
    isLoading,
    refetch: refetchProject,
  } = useGetProjectQuery(
    {
      id: projectId,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (data) {
      setProject(data.project);
      dispatch(
        setBlocksBoundingBox(
          (data.project.blocks_bounding_box as Nullable<BlocksBoundingBox>) ??
            null
        )
      );
    }
  }, [data]);

  const context: ProjectProviderContextValue = {
    isLoading,
    project,
    projectId,
    refetchProject,
  };

  if (!data && !isLoading) {
    return <NotFound resource={"project"} />;
  }

  return (
    <ProjectProviderContext.Provider value={context}>
      {children}
    </ProjectProviderContext.Provider>
  );
};
