import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useOrganization } from "~/features/organization/useOrganization";

export function useProjectTrackingProperties() {
  const { projectId = "" } = useParams<{ projectId: string }>();

  const { organization } = useOrganization();
  const orgSlug = organization?.slug ?? "";

  return useMemo(
    () => ({
      projectId,
      organization: orgSlug,
    }),
    [projectId, orgSlug]
  );
}
